import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setActivityStream = createAction('/activity/stream/set');

export const fetchActivityStream = ({ orgId }) => async dispatch => {
  const { data } = await axios.get(`/analytics/orgs/${orgId}/activity`);
  dispatch(setActivityStream({ orgId, data }));
  return data;
};

export default createReducer({
  [setActivityStream]: (state, { orgId, data }) => ({
    ...state,
    [orgId]: data,
  }),
}, {});

export const selectOrgActivityStream = (state, orgId) => state.activityStream[orgId];
