import { createAction, createReducer } from 'redux-act';
import moment from 'moment';
import axios from '../utils/api';

const setBeeToken = createAction('/htmlEditor/token/set');
const setTemplate = createAction('/htmlEditor/templates/set');

const templateApi = axios.create();

const selectState = state => state.htmlEditor;

export const selectBeeToken = state => selectState(state).token;

export const selectTemplate = (state, url) => selectState(state).templates[url];

function isTokenValid(token) {
  if (!token || !token['.expires']) {
    return false;
  }

  return moment(token['.expires']).isAfter();
}

export const getBeeToken = (editorType = 'email') => async (dispatch, getState) => {
  const state = getState();
  const existingToken = selectBeeToken(state);
  if (isTokenValid(existingToken)) {
    return existingToken;
  }

  const { data } = await axios.get(`/content/editor/${editorType}/token`);
  dispatch(setBeeToken(data));
  return data.token;
};

export const fetchTemplate = url => async dispatch => {
  const { data } = await templateApi.get(url);

  dispatch(setTemplate({ url, data }));
  return data;
};

export default createReducer({
  [setBeeToken]: (state, { token }) => ({
    ...state,
    token,
  }),
  [setTemplate]: (state, { url, data }) => ({
    ...state,
    templates: {
      ...state.templates,
      [url]: data,
    },
  }),
}, {
  token: undefined,
  templates: {},
});
