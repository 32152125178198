import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import { selectCurrentOrg } from './user';
import axios from '../utils/api';

const setContentPayload = createAction('/content/payload/set');
const setTemplateData = createAction('/content/template/set');
const deleteTemplateData = createAction('/content/template/delete');

const selectContentState = state => state.content || {};

export const fetchContent = ({ orgId, token }) => async dispatch => {
  const { data } = await axios({
    url: `/content/orgs/${orgId}/templates`,
    method: 'GET',
    params: {
      ...(token ? { token } : {}),
    },
  });
  dispatch(setContentPayload({ orgId, data, previousToken: token }));
  return data;
};

export const fetchTemplate = ({ orgId, templateId }) => async dispatch => {
  const { data } = await axios.get(`/content/orgs/${orgId}/templates/${templateId}`);
  dispatch(setTemplateData({ orgId, templateId, data }));
  return data;
};

export const saveTemplate = ({
  orgId,
  templateId,
  type,
  template,
  jsonTemplate,
  tags,
}) => async dispatch => {
  const { data } = await axios.post(`/content/orgs/${orgId}/templates`, {
    templateId,
    template,
    jsonTemplate,
    type,
    tags,
  });
  dispatch(setTemplateData({ orgId, templateId, data }));
  return data;
};

export const deleteTemplate = ({ orgId, templateId }) => async dispatch => {
  const { data } = await axios.delete(`/content/orgs/${orgId}/templates/${templateId}`);
  dispatch(deleteTemplateData({ orgId, templateId }));
  return data;
};

export default createReducer({
  [setContentPayload]: (state, { orgId, previousToken, data: { nextToken, data } }) => {
    const orgState = state[orgId] || {};
    const oldData = previousToken ? orgState.data || [] : [];

    return {
      ...state,
      [orgId]: {
        ...orgState,
        nextToken,
        data: oldData.concat(...data),
      },
    };
  },
  [setTemplateData]: (state, { orgId, templateId, data }) => {
    const orgState = state[orgId] || {};
    const oldData = orgState.data || [];
    const index = oldData.findIndex(t => t.templateId === templateId);
    let newData;
    if (!~index) {
      newData = oldData.concat(data);
    } else {
      newData = [...oldData.slice(0, index), data, ...oldData.slice(index + 1)];
    }

    return {
      ...state,
      [orgId]: {
        ...orgState,
        data: newData,
      },
    };
  },
  [deleteTemplateData]: (state, { orgId, templateId }) => {
    const orgState = state[orgId] || {};
    const oldData = orgState.data || [];

    return {
      ...state,
      [orgId]: {
        ...orgState,
        data: oldData.filter(t => t.templateId !== templateId),
      },
    };
  },
}, {});

const selectOrgContent = (state, orgId) => selectContentState(state)[orgId] || {};

export const selectContent = (state, orgId) => selectOrgContent(state, orgId).data;

export const selectNextToken = (state, orgId) => selectOrgContent(state, orgId).nextToken;

const selectTemplateId = (_, { templateId }) => templateId || '';

const newTemplate = {};

export const selectTemplate = createSelector(
  [selectCurrentOrg, selectTemplateId, selectContentState],
  (currentOrg, templateId, state) => {
    if (!templateId || templateId === 'new') return newTemplate;

    const orgContent = (state[currentOrg] || {}).data || [];
    return orgContent.find(t => t.templateId === templateId) || {};
  },
);
