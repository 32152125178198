import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setOrgDetails = createAction('/organizations/details/set');

export const fetchOrganizationDetails = ({ orgId }) => async dispatch => {
  const { data } = await axios.get(`/orgs/${orgId}`);
  dispatch(setOrgDetails(data));
  return data;
};

export const fetchUserOrgs = () => (dispatch, getState) => {
  const state = getState();
  const orgIds = Object.keys(state.user.roles);

  return Promise.all(orgIds.map(id => dispatch(fetchOrganizationDetails({ orgId: id }))));
};

export default createReducer({
  [setOrgDetails]: (state, payload) => ({
    ...state,
    [payload.id]: payload,
  }),
}, {});

export const selectOrganizationDetails = (state, orgId) => state.organizations[orgId];
