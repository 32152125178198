import React from 'react';
import PropTypes from 'prop-types';
import Button from '@x-functions/freyja/lib/components/button';
import AuthLayout from '../auth/layout';
import useUserRoles from '../../hooks/useUserRoles';

/* eslint-disable jsx-a11y/anchor-is-valid */

function OrgGuard({ children }) {
  const roles = useUserRoles();

  const handleTalkToUsClick = React.useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    window.Kommunicate.startConversation();
  }, []);

  if (!Object.values(roles).length) {
    return (
      <AuthLayout
        title="Your organization isn't on X-Functions yet!"
        subtitle="All users of X-Functions need to belong to an organization. Please contact your org admin or click below to contact us."
        hideSeparator
      >
        <Button color="dark" fullwidth onClick={handleTalkToUsClick}>Contact Us</Button>
      </AuthLayout>
    );
  }

  return (
    <>
      {children}
    </>
  );
}

OrgGuard.propTypes = {
  children: PropTypes.node,
};

OrgGuard.defaultProps = {
  children: null,
};

export default OrgGuard;
