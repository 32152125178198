import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setOrgIntegrations = createAction('/organizations/integrations/set');
const setNewOrgIntegration = createAction('/organizations/integrations/new');
const deleteOrgIntegration = createAction('/organizations/integrations/delete');

export const fetchOrganizationIntegrations = ({ orgId, appName, nextToken }) => async dispatch => {
  const { data } = await axios.get(`/vault/orgs/${orgId}`, { params: { appName, nextToken } });
  dispatch(setOrgIntegrations({ orgId, data }));
  return data;
};

export const createOrganizationIntegration = ({ orgId, integration }) => async dispatch => {
  const { data } = await axios.post(`/vault/orgs/${orgId}`, integration);
  dispatch(setNewOrgIntegration({ orgId, data }));
  return data;
};

export const fetchOrganizationIntegration = async ({ orgId, key }) => {
  const { data } = await axios.get(`/vault/orgs/${orgId}/item/${key}`, { params: { refreshIfNeeded: true } });
  return data;
};

export const deleteOrganizationIntegration = ({ orgId, key, ...rest }) => async dispatch => {
  dispatch(deleteOrgIntegration({ orgId, key }));
  try {
    await axios.delete(`/vault/orgs/${orgId}/item/${key}`);
  } catch (err) {
    dispatch(setNewOrgIntegration({ orgId, key, ...rest }));
    throw err;
  }
  return { orgId, key };
};

const isLocalTest = process.env.REACT_APP_LOCAL_TEST === 'true';

export const getAuthenticationUri = async ({ orgId, platform, state }) => {
  const { data } = await axios.get(`/vault/orgs/${orgId}/oauth/${platform}`, {
    params: {
      state,
      test: isLocalTest ? 'true' : undefined,
    },
  });

  return data;
};

export const getAccessToken = async ({ orgId, platform, originalUrl }) => {
  const { data } = await axios.post(`/vault/orgs/${orgId}/oauth/${platform}/token`, {
    originalUrl,
  }, {
    params: {
      test: isLocalTest ? 'true' : undefined,
    },
  });

  return data;
};

export default createReducer({
  [setOrgIntegrations]: (state, { orgId, data }) => ({
    ...state,
    [orgId]: data,
  }),
  [setNewOrgIntegration]: (state, { orgId, data }) => {
    const orgState = state[orgId] || {};
    const integrations = orgState.data || [];

    return {
      ...state,
      [orgId]: {
        ...orgState,
        data: [
          data,
          ...integrations,
        ],
      },
    };
  },
  [deleteOrgIntegration]: (state, { orgId, key }) => {
    const orgState = state[orgId] || {};
    const integrations = orgState.data || [];

    return {
      ...state,
      [orgId]: {
        ...orgState,
        data: integrations.filter(i => i.key !== key),
      },
    };
  },
}, {});

export const selectOrganizationIntegrations = (state, orgId) => state.orgIntegrations[orgId];
