import React from 'react';

interface ShellContextValue {
  drawerOpen: boolean
  setDrawerOpen: (open: boolean) => void
}

const initialValue = {
  drawerOpen: true,
  setDrawerOpen: () => undefined,
} as ShellContextValue;

const ShellContext = React.createContext(initialValue);

export default ShellContext;
