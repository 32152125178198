import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import throttle from './utils/throttle';

const persist = (state: object): void => {
  const dehydrated = JSON.stringify(state);
  try {
    window.localStorage.setItem('xfStore', dehydrated);
  } catch {
    // ignore any errors here - localStorage might fail due to user privacy settings
  }
};

const retrieve = (): object | undefined => {
  try {
    const dehydrated = window.localStorage.getItem('xfStore');
    if (dehydrated) {
      return JSON.parse(dehydrated);
    }
  } catch {
    // do nothing
  }
  return undefined;
};

interface Window { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R; }

const finalCreateStore = (initialState?: object) => {
  let composeEnhancers: ((<R>(a: R) => R) | undefined);
  if (process.env.NODE_ENV !== 'production') {
    composeEnhancers = (window as unknown as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
  if (!composeEnhancers) composeEnhancers = compose;

  const persistedState = retrieve();
  const store = createStore(
    rootReducer,
    { ...initialState, ...(persistedState || {}) },
    composeEnhancers(applyMiddleware(thunk)),
  );

  store.subscribe(throttle(() => {
    persist(store.getState());
  }));

  return store;
};

export default finalCreateStore;
