interface Map {
  [key: string]: any
}

export const normalize = (arr: Map[], idField = 'id'): [Map, string[]] => {
  const ids = arr.map(el => el[idField]);
  const normalized = arr.reduce((acc, curr) => ({
    ...acc,
    [curr[idField]]: curr,
  }), {});

  return [normalized, ids];
};

export const denormalize = (normalized: Map, ids = []): Map[] => {
  if (!ids || !ids.length) {
    return Object.values(normalized);
  }

  return ids.map(id => normalized[id]);
};
