import React from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandIcon from '@material-ui/icons/ExpandLess';
import PeopleIcon from '@material-ui/icons/People';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CodeIcon from '@material-ui/icons/Code';
import PostAddIcon from '@material-ui/icons/PostAdd';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import AuthContext from '../../auth/authContext';
import useCurrentOrg from '../../../hooks/useCurrentOrg';
import useCurrentUserData from '../../../hooks/useCurrentUserData';
import useScreenDimensions from '../../../hooks/useScreenDimensions';
import styles from './styles.module.sass';

export interface NavigationDrawerProps {
  open: boolean
  onClose: React.MouseEventHandler
  className?: string
}

function NavigationDrawer({
  open,
  onClose,
  className = undefined,
}: NavigationDrawerProps): React.ReactElement {
  const { breakpoints } = useScreenDimensions();
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [currentOrg, setOrg] = useCurrentOrg();
  const { email, roles } = useCurrentUserData();

  const authContext = React.useContext(AuthContext);

  const handleSignOut = React.useCallback(async () => {
    await Auth.signOut();
    /* @ts-ignore */
    authContext.onStateChange('signedOut');
  }, [authContext]);

  const handleMenuOpenClick = React.useCallback((e: React.MouseEvent) => {
    setMenuAnchor(e.currentTarget as HTMLElement);
  }, []);

  const handleMenuClose = React.useCallback(() => setMenuAnchor(null), []);

  const handleNavigate = React.useCallback((e: React.MouseEvent) => {
    if (breakpoints.upTo('lg')) onClose(e);
  }, [breakpoints, onClose]);

  return (
    <Drawer
      open={open}
      variant={breakpoints.upTo('lg') ? 'temporary' : 'persistent'}
      classes={{ paper: className }}
      onClose={onClose}
    >
      <div className={styles.root}>
        <div className={styles.header}>
          <Typography variant="h5">MakerOps</Typography>
          <IconButton onClick={onClose} size="medium">
            <MenuOpenIcon />
          </IconButton>
        </div>
        <div className={styles.body}>
          <List>
            <ListItem button component={Link} to="/" onClick={handleNavigate}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/organization" onClick={handleNavigate}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Organization" />
            </ListItem>
            <ListItem button component={Link} to="/audience" onClick={handleNavigate}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Audience" />
            </ListItem>
            <ListItem button component={Link} to="/workflows" onClick={handleNavigate}>
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary="Workflows" />
            </ListItem>
            <ListItem button component={Link} to="/functions" onClick={handleNavigate}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary="Functions" />
            </ListItem>
            <ListItem button component={Link} to="/content" onClick={handleNavigate}>
              <ListItemIcon>
                <PostAddIcon />
              </ListItemIcon>
              <ListItemText primary="Content" />
            </ListItem>
            <ListItem button component={Link} to="/query" onClick={handleNavigate}>
              <ListItemIcon>
                <QueryBuilderIcon />
              </ListItemIcon>
              <ListItemText primary="Query" />
            </ListItem>
          </List>
        </div>
        <div className={styles.footer}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar variant="rounded" classes={{ colorDefault: styles.avatarRoot }}>{email?.substr(0, 1)?.toUpperCase()}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={email} secondary={currentOrg} primaryTypographyProps={{ noWrap: true }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={handleMenuOpenClick}>
                  <ExpandIcon />
                </IconButton>
                <Menu
                  anchorEl={menuAnchor}
                  keepMounted
                  open={Boolean(menuAnchor)}
                  onClose={handleMenuClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  {roles && Object.keys(roles).length > 1 ? (
                    Object.keys(roles).filter(id => id !== currentOrg).map(id => (
                      <MenuItem key={id} onClick={() => setOrg({ orgId: id })}>
                        {id}
                      </MenuItem>
                    )).concat(<Divider key="divider" />)
                  ) : null}
                  <MenuItem key="signout" onClick={handleSignOut}>Sign out</MenuItem>
                </Menu>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
      </div>
    </Drawer>
  );
}

export default NavigationDrawer;
