import React from 'react';
import Modal from '@x-functions/freyja/lib/components/modal';
import Button from '@x-functions/freyja/lib/components/button';

interface ConfirmModalProps {
  open?: boolean
  title?: React.ReactNode
  body: React.ReactNode
  confirmText?: string
  cancelText?: string
  onClose: (result: boolean) => void
}

function ConfirmModal({
  open = false,
  title = 'Please Confirm',
  body,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onClose,
}: ConfirmModalProps) {
  const onCancel = React.useCallback(() => {
    onClose(false);
  }, [onClose]);

  const onConfirm = React.useCallback(() => {
    onClose(true);
  }, [onClose]);

  return (
    <Modal show={open} onClose={onCancel}>
      <Modal.Card>
        <Modal.Card.Head onClose={onCancel}><div className="is-flex is-flex-grow-1">{title}</div></Modal.Card.Head>
        <Modal.Card.Body>{body}</Modal.Card.Body>
        <Modal.Card.Foot>
          <Button.Group>
            <Button onClick={onCancel}>{cancelText}</Button>
            <Button color="dark" onClick={onConfirm}>{confirmText}</Button>
          </Button.Group>
        </Modal.Card.Foot>
      </Modal.Card>
    </Modal>
  );
}

export default ConfirmModal;
