import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setClientApps = createAction('/api/clientApps/set');
const setClientApp = createAction('/api/clientApp/set');
const deleteClientAppAction = createAction('/api/clientApp/delete');

const selectState = state => state.apiClientApps;

export const selectClientApps = (state, { orgId }) => selectState(state)[orgId];

export const fetchClientApps = ({
  orgId,
}) => async dispatch => {
  const { data } = await axios.get(`/websiteanalytics/orgs/${orgId}/apps`);

  dispatch(setClientApps({
    orgId,
    data,
  }));
  return data;
};

export const upsertClientApp = ({
  orgId,
  app,
}) => async dispatch => {
  const { data } = await axios.post(`/websiteanalytics/orgs/${orgId}/apps/${app.appId}`, app);

  dispatch(setClientApp({
    orgId,
    data,
  }));
  return data;
};

export const deleteClientApp = ({
  orgId,
  appId,
}) => async dispatch => {
  const { data } = await axios.delete(`/websiteanalytics/orgs/${orgId}/apps/${appId}`);

  dispatch(deleteClientAppAction({
    orgId,
    appId,
  }));
  return data;
};

export default createReducer({
  [setClientApps]: (state, { orgId, data }) => ({
    ...state,
    [orgId]: data,
  }),
  [setClientApp]: (state, { orgId, data }) => {
    const { appId } = data;
    const apps = state[orgId] || [];
    return {
      ...state,
      [orgId]: apps.filter(app => app.appId !== appId).concat(data),
    };
  },
  [deleteClientAppAction]: (state, { orgId, appId }) => {
    const apps = state[orgId] || [];
    return {
      ...state,
      [orgId]: apps.filter(app => app.appId !== appId),
    };
  },
}, {});
