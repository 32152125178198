import React from 'react';
import { useDispatch } from 'react-redux';

function useActions(actionMap, noDispatch) {
  const dispatch = useDispatch();
  const actions = React.useMemo(() => {
    const val = Object.keys(actionMap).reduce((acc, curr) => ({
      ...acc,
      [curr]: (...args) => dispatch(actionMap[curr](...args)),
    }), noDispatch || {});

    return val;
  }, [actionMap, noDispatch, dispatch]);

  return actions;
}

export default useActions;
