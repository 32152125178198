import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setFunctionExecutions = createAction('/functions/executions/set');
const setFunctionMetrics = createAction('/functions/metrics/set');

const selectState = state => state.functionUsage;

export const selectFunctionExecutions = (state, { orgId, functionName }) => selectState(state)[`${orgId}/${functionName}`];

export const selectFunctionMetrics = (state, { orgId, functionName }) => selectState(state)[`${orgId}/${functionName}`]?.metrics;

export const fetchFunctionExecutions = ({
  orgId,
  functionName,
  token,
  statuses,
  types,
  period,
}) => async dispatch => {
  const { data: { data, nextToken } } = await axios.get(`/rpa/orgs/${orgId}/functions/${functionName}/executions`, {
    params: {
      token,
      statuses,
      types,
      period: period || 'days',
      limit: 1000,
    },
  });

  dispatch(setFunctionExecutions({
    orgId,
    functionName,
    token,
    nextToken,
    data,
  }));
  return data;
};

export const fetchFunctionMetrics = ({
  orgId,
  functionName,
  period,
}) => async dispatch => {
  const { data } = await axios.get(`/rpa/orgs/${orgId}/functions/${functionName}/metrics`, {
    params: {
      period: period || 'days',
    },
  });

  dispatch(setFunctionMetrics({
    orgId,
    functionName,
    data,
  }));
  return data;
};

export default createReducer({
  [setFunctionExecutions]: (state, {
    orgId,
    functionName,
    data,
    token,
    nextToken,
  }) => {
    const execState = state[`${orgId}/${functionName}`] || {};
    const items = token ? execState.data || [] : [];

    return {
      ...state,
      [`${orgId}/${functionName}`]: {
        ...execState,
        data: [...items, ...data],
        nextToken,
      },
    };
  },
  [setFunctionMetrics]: (state, {
    orgId,
    functionName,
    data,
  }) => {
    const execState = state[`${orgId}/${functionName}`] || {};

    return {
      ...state,
      [`${orgId}/${functionName}`]: {
        ...execState,
        metrics: data,
      },
    };
  },

}, {});
