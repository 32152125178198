import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentOrg } from '../reducers/user';
import useActions from './useActions';
import {
  search,
  selectSearchResults,
  clearResults,
} from '../reducers/globalSearch';

const actionCreators = {
  search,
  clearResults,
};

export function useSearchActions() {
  const actions = useActions(actionCreators);

  return actions;
}

function useGlobalSearch() {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const results = useSelector(state => selectSearchResults(state, orgId), shallowEqual);
  const actions = useSearchActions();

  return {
    orgId,
    results,
    actions,
  };
}

export default useGlobalSearch;
