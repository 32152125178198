import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@x-functions/freyja/lib/components/menu';
import { SearchResult } from './types';

interface MenuItemProps {
  item: SearchResult
  type: string
  onNavigate: React.MouseEventHandler
}

function MenuItem({
  item,
  type,
  onNavigate,
}: MenuItemProps) {
  const name = item.functionName || item.workflowName || item.name || item.aspect.split('::')[1];

  const getLink = () => {
    switch (type) {
      case 'functions':
        return `/functions?selected=${item.aspect.split('::')[1]}`;
      case 'workflows':
        return `/workflows/${item.workflowId}`;
      case 'users':
        return `/audience/users/${item.userId}`;
      case 'segments':
        return `/audience/segments/${item.segmentId}`;
      default:
        return '#';
    }
  };

  return (
    <Menu.List.Item
      renderAs={Link}
      to={getLink()}
      onClick={onNavigate}
    >
      {name as unknown as string[]}
    </Menu.List.Item>
  );
}

export default MenuItem;
