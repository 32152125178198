const throttle = (fn: (...args: any) => void, wait: number = 300) => {
  let throttled = false;
  return (...args: any[]) => {
    // It's throttled - just return
    if (throttled) return;

    // Apply function
    fn(...args);

    // start throttling after a call
    throttled = true;
    setTimeout(() => {
      // Throttle it for `${wait}` ms
      throttled = false;
    }, wait);
  };
};

export default throttle;
