import { createAction, createReducer } from 'redux-act';
import { updateFunctionProps } from './functionDetails';
import axios from '../utils/api';

export const setVersionDetails = createAction('/version/details/set');

/** Selectors */

const selectState = state => state.versionInfo;

export const selectVersionInfo = (state, { orgId, functionName, version }) => selectState(state)[`${orgId}/${functionName}:${version}`];

/** Action dispatchers */

export const createNewVersion = ({
  orgId,
  functionName,
  source,
  fromVersion,
  ...rest
}) => async dispatch => {
  const { data: { newVersion } } = await axios.put(`/rpa/orgs/${orgId}/functions/${functionName}`, {
    ...rest,
    orgId,
    functionName,
    source,
    fromVersion,
    deploy: true,
  });

  dispatch(updateFunctionProps({ orgId, functionName, props: { draft: source, newVersion } }));
  dispatch(setVersionDetails({
    orgId,
    functionName,
    version: newVersion.version,
    data: newVersion,
  }));

  return newVersion;
};

export const fetchVersionInfo = ({ orgId, functionName, version }) => async dispatch => {
  const { data } = await axios.get(`/rpa/orgs/${orgId}/functions/${functionName}/versions/${version}`);
  dispatch(setVersionDetails({ orgId, functionName, version }));
  return data;
};

export const updateVersionInfo = ({
  orgId,
  functionName,
  version,
  displayName,
  description,
}) => async dispatch => {
  const { data } = await axios.put(`/rpa/orgs/${orgId}/functions/${functionName}/versions/${version}`, {
    orgId,
    functionName,
    version,
    displayName,
    description,
  });

  dispatch(updateFunctionProps({ orgId, functionName, props: { newVersion: data } }));
  dispatch(setVersionDetails({
    orgId,
    functionName,
    version,
    data,
  }));

  return data;
};

export default createReducer({
  [setVersionDetails]: (state, {
    orgId,
    functionName,
    version,
    data,
  }) => ({
    ...state,
    [`${orgId}/${functionName}:${version}`]: data,
  }),
}, {});
