import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import { selectCurrentOrg } from './user';
import axios from '../utils/api';

const setMetrics = createAction('/websiteanalytics/metrics/set');
export const setCurrentPeriod = createAction('/websiteanalytics/metrics/days/set');

export const fetchWebsiteAnalyticsMetrics = ({ orgId, days = 2, cancelToken }) => async dispatch => {
  const opts = cancelToken ? { cancelToken: cancelToken.token } : {};
  const { data } = await axios.get(`/websiteanalytics/orgs/${orgId}/metrics?days=${days}`, opts);
  dispatch(setMetrics({ orgId, data, days }));
  return data;
};

export default createReducer({
  [setMetrics]: (state, { orgId, data, days }) => {
    const orgData = state[orgId] || {};
    return {
      ...state,
      days,
      [orgId]: {
        ...orgData,
        [days]: data.metrics,
      },
    };
  },
  [setCurrentPeriod]: (state, days) => ({
    ...state,
    days: Number(days) || 2,
  }),
}, { days: 2 });

export const selectMetricsState = state => state.metrics;

export const selectWebsiteAnalyticsMetrics = (state, orgId) => state.metrics[orgId];

export const selectCurrentPeriod = state => state.metrics.days;

export const selectMetricsForVis = createSelector(
  [selectCurrentOrg, selectMetricsState],
  (currentOrg, metrics) => {
    const { days } = metrics;
    const orgMetrics = metrics[currentOrg] || {};
    const periodMetrics = orgMetrics[days] || {};
    const allMetrics = [].concat(...Object.values(periodMetrics));

    const byMetric = allMetrics.reduce((acc, curr) => {
      const m = acc[curr.name] || [{ id: curr.name, data: [] }];
      return {
        ...acc,
        [curr.name]: [{
          ...m[0],
          data: [{ x: curr.period, y: curr.value }, ...m[0].data],
        }],
      };
    }, {});

    return byMetric;
  },
);
