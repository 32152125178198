import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setQuery = createAction('/organizations/queryBuider/query/set');
const setQueryResults = createAction('/organizations/queryBuilder/query/setResults');
export const clearQuery = createAction('/organizations/queryBuilder/query/clear');

export const selectOrgQueries = (state, orgId) => state.queryBuilder[orgId];

export const startQuery = ({ orgId, sql }) => async dispatch => {
  const { data: { queryId, error } } = await axios.post(`/analytics/orgs/${orgId}/query/run`, {
    query: sql,
  });

  if (queryId) {
    dispatch(setQuery({ orgId, sql, queryId }));
  }

  return { queryId, error };
};

export const fetchQueryResults = ({
  orgId,
  queryId,
  token = undefined,
  pageSize = 20,
}) => async (dispatch, getState) => {
  const query = selectOrgQueries(getState(), orgId)[queryId];
  if (query && query.status && query.status !== 'RUNNING') return queryId;

  const {
    data: {
      status,
      items,
      nextToken,
      error,
    },
  } = await axios.post(`/analytics/orgs/${orgId}/query/run`, {
    queryId,
    pageSize,
    nextToken: token || (query && query.nextToken) || '0',
  });

  dispatch(setQueryResults({
    orgId,
    queryId,
    items,
    nextToken,
    token,
    error,
    status,
  }));

  if (status === 'RUNNING' && !error) {
    setTimeout(() => dispatch(fetchQueryResults({
      orgId,
      queryId,
      token,
      pageSize,
    }), 1000));
  }

  return queryId;
};

export default createReducer({
  [setQuery]: (state, { orgId, queryId, sql }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      [queryId]: {
        id: queryId,
        sql,
      },
    },
  }),
  [setQueryResults]: (state, {
    orgId,
    queryId,
    items,
    token,
    nextToken,
    error,
    status,
  }) => {
    const originalQuery = (state[orgId] || {})[queryId] || {};
    const originalItems = originalQuery.items || [];
    let newItems = items;
    if (token) {
      newItems = [...originalItems, ...newItems];
    }

    return {
      ...state,
      [orgId]: {
        ...(state[orgId] || {}),
        [queryId]: {
          ...originalQuery,
          items: newItems,
          nextToken,
          error,
          status,
        },
      },
    };
  },
  [clearQuery]: (state, { orgId, queryId }) => {
    const orgState = { ...state[orgId] };
    delete orgState[queryId];

    return {
      ...state,
      [orgId]: orgState,
    };
  },
}, {});
