import React from 'react';
import NavigationDrawer from './drawer';
import ShellContext from './context';
import useScreenDimensions from '../../hooks/useScreenDimensions';
import styles from './styles.module.sass';

export interface AppShellProps {
  children?: React.ReactNode
}

function AppShell({ children = null }: AppShellProps): React.ReactElement {
  const { breakpoints } = useScreenDimensions();
  const [open, setOpen] = React.useState(breakpoints.moreThan('lg'));

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const providerState = React.useMemo(() => ({
    drawerOpen: open,
    setDrawerOpen: setOpen,
  }), [open]);

  return (
    <div>
      <NavigationDrawer
        open={open}
        onClose={handleClose}
        className={styles.drawer}
      />
      <ShellContext.Provider value={providerState}>
        {children}
      </ShellContext.Provider>
    </div>
  );
}

export default AppShell;
