import React from 'react';
import PropTypes from 'prop-types';
import GooglePickerContext from './context';

function GooglePickerProvider({ children }) {
  const [initialized, setInitialized] = React.useState(false);
  //
  // Trick to use the setInitialized function inside the global scope
  const setInitializedRef = React.useRef(setInitialized);
  setInitializedRef.current = setInitialized;

  // Load the API
  React.useEffect(() => {
    window.googlePickerLoaded = () => {
      window.gapi.load('picker', { callback: () => setInitializedRef.current(true) });
    };
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://apis.google.com/js/api.js?onload=googlePickerLoaded';
    const h = document.getElementsByTagName('head')[0];
    h.appendChild(s);
  }, []);

  const pick = React.useCallback(async ({
    authToken,
    selectFolders = true,
    mimeTypes = undefined,
  }) => new Promise(resolve => {
    if (!initialized) return resolve({ action: 'not loaded' });

    const view = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS);
    view.setIncludeFolders(true);
    view.setSelectFolderEnabled(selectFolders);
    view.setMimeTypes(mimeTypes);

    const pkr = new window.google.picker.PickerBuilder()
      .addView(view)
      .setDeveloperKey(process.env.REACT_APP_GOOGLE_PICKER_DEVELOPER_KEY)
      .setOAuthToken(authToken)
      .setCallback(result => {
        if (result?.action !== 'loaded') resolve(result);
      })
      .build();

    pkr.setVisible(true);

    return pkr;
  }), [initialized]);

  const providerState = React.useMemo(() => ({ pick, initialized }), [pick, initialized]);

  return (
    <GooglePickerContext.Provider value={providerState}>
      {children}
    </GooglePickerContext.Provider>
  );
}

GooglePickerProvider.propTypes = {
  children: PropTypes.node,
};

GooglePickerProvider.defaultProps = {
  children: null,
};

export default GooglePickerProvider;
