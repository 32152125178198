import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setOrgFunctions = createAction('/organizations/functions/set');
const setOrgFolderStructure = createAction('/organizations/functions/folders');
export const setNewOrgFunction = createAction('/organizations/functions/new');
export const expandFolder = createAction('/organizations/functions/folders/expand');
export const collapseFolder = createAction('/organizations/functions/folders/collapse');
export const expandFunctionPath = createAction('/organizations/functions/folders/expandPath');
export const setExpandedFolders = createAction('/organizations/functions/expandedFolders');
export const addExpandedFolders = createAction('/organizations/functions/addExpandedFolders');
export const updateOrgFunction = createAction('/organizations/functions/update');
export const removeOrgFunction = createAction('/organizations/functions/delete');

export const selectOrganizationFunctions = (state, orgId) => state.orgFunctions[orgId] || {};

export const selectFunction = (state, { orgId, functionName }) => {
  const orgState = selectOrganizationFunctions(state, orgId) || {};
  const orgFunctions = orgState.data || [];
  return orgFunctions.find(f => f.functionName === functionName);
};

export const selectIsNewFunction = (state, { orgId, functionName }) => {
  const fn = selectFunction(state, { orgId, functionName });
  return fn?.isNew || false;
};

export const updateFunctionScope = ({ orgId, functionName, scope }) => (dispatch, getState) => {
  const state = getState();
  const orgState = selectOrganizationFunctions(state, orgId) || {};
  const orgFunctions = orgState.data || [];
  const fn = orgFunctions.find(f => f.functionName === functionName);
  if (fn) {
    dispatch(updateOrgFunction({
      orgId,
      functionName,
      data: {
        ...fn,
        scope,
      },
    }));
  }
};

export const fetchOrganizationFunctions = ({
  orgId,
  token,
  query,
  scope,
}) => async dispatch => {
  const [{ data: functions }, { data: folders }] = await Promise.all([
    axios.get(`/rpa/orgs/${orgId}/functions`, {
      params: {
        token,
        query,
        scope,
        limit: 1000,
      },
    }),
    axios.get(`/rpa/orgs/${orgId}/folders`),
  ]);

  dispatch(setOrgFunctions({
    orgId,
    functions,
    folders,
    token,
    query: query || '',
    scope: scope || '',
  }));
  return functions;
};

export const updateOrganizationFolders = ({
  orgId,
  folders,
}) => async (dispatch, getState) => {
  const state = getState();
  const orgState = selectOrganizationFunctions(state, orgId) || {};
  const oldFolders = orgState.folders;

  dispatch(setOrgFolderStructure({ orgId, folders }));
  try {
    const { data } = await axios.put(`/rpa/orgs/${orgId}/folders`, { folders });
    return data;
  } catch (err) {
    dispatch(setOrgFolderStructure({ orgId, folders: oldFolders }));
    throw err;
  }
};

export const updateOrganizationFunction = ({
  orgId,
  functionName,
  displayName,
  description,
  tags,
  source,
  deploy,
  fromVersion,
}) => async (dispatch, getState) => {
  const state = getState();
  const orgState = selectOrganizationFunctions(state, orgId) || {};
  const orgFunctions = orgState.data || [];
  const fn = orgFunctions.find(f => f.functionName === functionName);

  if (fn) {
    dispatch(updateOrgFunction({
      orgId,
      functionName,
      data: {
        ...fn,
        displayName: displayName || fn.displayName,
        description: description || fn.description,
        tags: tags || fn.tags,
        isNew: false,
      },
    }));
  }

  if (functionName.startsWith('new')) return fn;

  try {
    const { data } = await axios.put(`/rpa/orgs/${orgId}/functions/${functionName}`, {
      orgId,
      functionName,
      displayName,
      description,
      tags,
      source,
      fromVersion,
      deploy,
    });
    dispatch(updateOrgFunction({ orgId, functionName, data: { ...data, isNew: false } }));
    return data;
  } catch (err) {
    if (fn) {
      dispatch(updateOrgFunction({ orgId, functionName, data: fn }));
    }
    throw err;
  }
};

const updateFunctionDisplayNameAfterCreation = ({ orgId, functionName }) => async (dispatch, getState) => {
  const state = getState();
  const orgState = selectOrganizationFunctions(state, orgId) || {};
  const orgFunctions = orgState.data || [];
  const fn = orgFunctions.find(f => f.functionName === functionName);

  if (fn && fn.displayName && fn.displayName !== 'new function') {
    dispatch(updateOrganizationFunction({ orgId, functionName, displayName: fn.displayName }));
  }
};

export const createOrganizationFunction = ({ orgId, temporaryId, fn }) => async dispatch => {
  dispatch(setNewOrgFunction({
    orgId,
    data: {
      orgId,
      functionName: temporaryId,
      displayName: 'new function',
      description: 'Give your function a description',
      tags: [],
      createdAt: new Date().toISOString(),
      scope: 'private',
      statuses: [{ version: 1, status: 'CREATED', timestamp: +(new Date()) }],
      version: 1,
      status: 'CREATED',
      folder: fn?.folder || 'root',
      isNew: true,
      creating: true,
      versions: [{
        version: 'draft',
        displayName: 'Draft',
        orgId,
        functionName: temporaryId,
      }],
    },
  }));

  const { data } = await axios.post(`/rpa/orgs/${orgId}/functions`, fn);
  dispatch(updateOrgFunction({ orgId, functionName: temporaryId, data: { ...data, isNew: true, creating: false } }));
  dispatch(updateFunctionDisplayNameAfterCreation({ orgId, functionName: data.functionName }));
  return data;
};

export const updateOrganizationFunctionFolder = ({ orgId, functionName, folder }) => async (dispatch, getState) => {
  const state = getState();
  const orgState = selectOrganizationFunctions(state, orgId) || {};
  const orgFunctions = orgState.data || [];
  const fn = orgFunctions.find(f => f.functionName === functionName);
  if (!fn) return fn;
  if (fn.functionName.startsWith('new')) return fn;

  try {
    dispatch(updateOrgFunction({ orgId, functionName, data: { ...fn, folder } }));
    const { data } = await axios.put(`/rpa/orgs/${orgId}/functions/${functionName}/folder`, { orgId, functionName, folder });
    return data;
  } catch (err) {
    dispatch(updateOrgFunction({ orgId, functionName, data: fn }));
    throw err;
  }
};

export const deleteOrganizationFunction = ({
  orgId,
  functionName,
}) => async (dispatch, getState) => {
  const state = getState();
  const orgState = selectOrganizationFunctions(state, orgId) || {};
  const orgFunctions = orgState.data || [];
  const fn = orgFunctions.find(f => f.functionName === functionName);
  if (fn) {
    dispatch(removeOrgFunction({
      orgId,
      functionName,
    }));
  }
  if (fn?.functionName?.startsWith('new')) return fn;

  try {
    const { data } = await axios.delete(`/rpa/orgs/${orgId}/functions/${functionName}`);
    return data;
  } catch (err) {
    dispatch(updateOrgFunction({ orgId, functionName, data: fn }));
    throw err;
  }
};

const expandFolderReducer = (state, { orgId, folder }) => {
  const folders = (state[orgId] || {}).folders || [];
  const allFolders = [];
  let current = folders.find(f => f.id === folder);
  while (current) {
    allFolders.push(current.id);
    if (current.parent && current.parent !== 'root') {
      const newId = current.parent;
      current = folders.find(f => f.id === newId);
    } else {
      current = undefined;
    }
  }

  if (allFolders.length < 1) return state;

  return {
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      expandedFolders: Array.from([...((state[orgId] || {}).expandedFolders || []), ...allFolders]),
    },
  };
};

export default createReducer({
  [setOrgFunctions]: (state, {
    orgId,
    functions: { data, nextToken },
    folders,
    token,
    query,
  }) => {
    const orgState = state[orgId] || {};
    const prevItems = orgState.data || [];
    const prevQuery = orgState.query || '';
    const paginating = (prevQuery === query) && token;

    const newItems = paginating ? [...prevItems, ...data] : data;

    return {
      ...state,
      [orgId]: {
        ...orgState,
        data: newItems,
        folders,
        nextToken,
        query,
      },
    };
  },
  [setOrgFolderStructure]: (state, { orgId, folders }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      folders,
    },
  }),
  [expandFunctionPath]: (state, { orgId, functionName }) => {
    const orgState = state[orgId] || {};
    const functions = orgState.data || [];
    const fn = functions.find(f => f.functionName === functionName);
    if (!fn) return state;

    return expandFolderReducer(state, { orgId, folder: fn.folder });
  },
  [expandFolder]: expandFolderReducer,
  [collapseFolder]: (state, { orgId, folder }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      expandedFolders: ((state[orgId] || {}).expandedFolders || []).filter(f => f !== folder),
    },
  }),
  [setExpandedFolders]: (state, { orgId, expandedFolders }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      expandedFolders,
    },
  }),
  [addExpandedFolders]: (state, { orgId, folders }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      expandedFolders: Array.from([...((state[orgId] || {}).expandedFolders || []), ...folders]),
    },
  }),
  [setNewOrgFunction]: (state, { orgId, data }) => {
    const orgState = state[orgId] || {};
    const functions = orgState.data || [];
    return {
      ...state,
      [orgId]: {
        ...orgState,
        data: functions.concat(data),
      },
    };
  },
  [updateOrgFunction]: (state, { orgId, functionName, data }) => {
    const orgState = state[orgId] || {};
    const functions = orgState.data || [];
    const idx = functions.findIndex(fn => fn.functionName === functionName);
    const oldFunction = functions[idx] || {};
    const isNew = 'isNew' in data ? data.isNew : oldFunction.isNew;
    let { displayName } = data;
    if (displayName === 'new function' && oldFunction.displayName) displayName = oldFunction.displayName;

    const newFunctions = !~idx
      ? functions.concat(data)
      : [...functions.slice(0, idx), {
        ...oldFunction,
        ...data,
        displayName,
        isNew: isNew || false,
      }, ...functions.slice(idx + 1)];

    return {
      ...state,
      [orgId]: {
        ...orgState,
        data: newFunctions,
      },
    };
  },
  [removeOrgFunction]: (state, { orgId, functionName }) => {
    const orgState = state[orgId] || {};
    const functions = orgState.data || [];

    return {
      ...state,
      [orgId]: {
        ...orgState,
        data: functions.filter(f => f.functionName !== functionName),
      },
    };
  },
}, {});
