import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import undoable, { ActionCreators } from 'redux-undo';
import { setOrgWorkflow } from './orgWorkflows';
import { updateWorkflowDetails } from './actions';
import {
  findNodeById,
  findParentNode,
  updateNodeById,
  deleteNodeByPath,
  updateNodeLink,
  insertNodeAfter,
  insertNodeBefore,
  insertFirstNode,
} from '../utils/tree';
import axios from '../utils/api';

const setWorkflow = createAction('/workflowEditor/set');
const setWorkflowDetails = createAction('/workflowEditor/details/set');
export const setNodes = createAction('/workflowEditor/nodes/set');
export const updateWorkflowName = createAction('/workflowEditor/updateWorkflowName');
export const selectNode = createAction('/workflowEditor/selectNode');
export const setWorkflowExecutions = createAction('/workflowEditor/setExecutions');
export const setCurrentExecution = createAction('/workflowEditor/setCurrentExecution');
export const setEditorMode = createAction('/workflowEditor/setMode');
export const addNode = createAction('/workflowEditor/nodes/add');
export const updateNode = createAction('/workflowEditor/nodes/update');
export const removeNode = createAction('/worlfowEditor/nodes/remove');
export const { undo } = ActionCreators;
export const { redo } = ActionCreators;
const setExecutionEvents = createAction('/workflowEditor/setCurrentExecution');

const initialState = {
  mode: 'edit',
  view: 'tree',
  workflow: {
    workflowId: '',
    selectedNode: '',
    selectedSubnode: '',
    selectedBranchIndex: 0,
    name: 'New Workflow',
    firstNode: '',
    nodes: {
      event: {
        id: 'event',
        type: 'event',
        name: 'Event',
        eventType: '',
        onSuccess: '',
      },
    },
  },
  details: undefined,
  executions: undefined,
  currentExecution: undefined,
};

export const loadWorkflow = ({ orgId, workflowId }) => async dispatch => {
  if (!workflowId || (workflowId === 'new')) {
    const val = dispatch(setWorkflow(initialState.workflow));
    dispatch(ActionCreators.clearHistory());
    return val;
  }

  const { data: { definition, ...details } } = await axios.get(`/rpa/orgs/${orgId}/workflows/${workflowId}`);
  if (!definition) throw new Error('Could not find workflow definition!');
  if (!details) throw new Error('Could not find workflow details!');

  const wf = dispatch(setWorkflow({ ...JSON.parse(definition), workflowId }));
  dispatch(setWorkflowDetails({ details }));
  dispatch(ActionCreators.clearHistory());
  return wf;
};

export const createNewWorkflow = ({ orgId }) => async dispatch => {
  dispatch(setWorkflow(initialState.workflow));
  dispatch(ActionCreators.clearHistory());

  const { data } = await axios.post(`/rpa/orgs/${orgId}/workflows`, { workflowName: 'New Workflow', workflow: initialState.workflow });
  dispatch(setOrgWorkflow({ orgId, data }));

  const { workflowId, definition, ...details } = data;
  const wf = dispatch(setWorkflow({ ...JSON.parse(definition), workflowId }));
  dispatch(setWorkflowDetails({ details }));
  dispatch(ActionCreators.clearHistory());
  return wf;
};

export const saveWorkflow = ({ orgId, deploy }) => async (dispatch, getState) => {
  const {
    workflow: {
      selectedNode,
      selectedSubnode,
      selectedBranchIndex,
      ...workflow
    },
  } = getState().workflowEditor.present;

  let data;
  if (!workflow.workflowId) {
    const response = await axios.post(`/rpa/orgs/${orgId}/workflows`, { workflowName: workflow.name, workflow, deploy });
    data = response.data;
  } else {
    const response = await axios.put(`/rpa/orgs/${orgId}/workflows/${workflow.workflowId}`, {
      ...workflow,
      workflowName: workflow.name,
      deploy,
    });
    data = response.data;
  }

  dispatch(setOrgWorkflow({ orgId, data }));
  return data;
};

export const fetchWorkflowExecutions = ({ orgId }) => async (dispatch, getState) => {
  const { workflow } = getState().workflowEditor.present;
  if (!workflow.workflowId) return undefined;

  const { data } = await axios.get(`/rpa/orgs/${orgId}/workflows/${workflow.workflowId}/exec`, workflow);
  dispatch(setWorkflowExecutions({ orgId, data }));

  return data;
};

export const fetchExecutionEvents = ({ orgId, executionName }) => async (dispatch, getState) => {
  const { workflow } = getState().workflowEditor.present;
  if (!workflow.workflowId) return undefined;

  const { data } = await axios.get(`/rpa/orgs/${orgId}/workflows/${workflow.workflowId}/exec/${executionName}`);
  dispatch(setExecutionEvents({ executionName, data }));

  return data;
};

export const startNewExecution = ({ orgId, workflowId, payload }) => async dispatch => {
  const { data: { executionName } } = await axios.post(`/rpa/orgs/${orgId}/workflows/${workflowId}/exec`, payload);

  const pollExecutionDetails = async () => {
    const { status } = await dispatch(fetchExecutionEvents({ orgId, executionName }));
    if (status === 'RUNNING') {
      setTimeout(pollExecutionDetails, 3000);
    }
  };
  await pollExecutionDetails();
  await dispatch(setCurrentExecution({ executionName }));
  return { executionName };
};

const blockWhenMonitoring = fn => (state, ...args) => {
  if (state.mode === 'monitor') return state;

  return fn(state, ...args);
};

const reducer = createReducer({
  [setWorkflow]: (_, payload) => ({
    mode: 'edit',
    view: 'tree',
    workflow: payload,
  }),
  [setNodes]: (state, nodes) => ({
    ...state,
    workflow: {
      ...state.workflow,
      nodes,
    },
  }),
  [setWorkflowExecutions]: (state, { data: { executions } }) => ({
    ...state,
    executions,
  }),
  [setExecutionEvents]: (state, { data: execution }) => {
    const execs = state.executions || [];
    const executionIndex = execs.findIndex(e => e.executionName === execution.executionName);
    const newExecutions = executionIndex > -1
      ? [...execs.slice(0, executionIndex), execution, ...execs.slice(executionIndex + 1)]
      : [execution, ...execs];

    return {
      ...state,
      executions: newExecutions,
    };
  },
  [setCurrentExecution]: (state, { executionName }) => ({
    ...state,
    currentExecution: executionName,
  }),
  [setWorkflowDetails]: (state, { details }) => ({
    ...state,
    details,
  }),
  [updateWorkflowDetails]: (state, data) => {
    if (state.workflow.workflowId === data.workflowId) {
      return {
        ...state,
        details: data,
      };
    }

    return state;
  },
  [setEditorMode]: (state, { mode, view }) => ({
    ...state,
    mode,
    view: view || state.view,
  }),
  [updateWorkflowName]: blockWhenMonitoring((state, { name }) => ({
    ...state,
    workflow: {
      ...state.workflow,
      name,
    },
    details: {
      ...state.details,
      workflowName: name,
    },
  })),
  [selectNode]: (state, { id, childId, branchId }) => ({
    ...state,
    workflow: {
      ...state.workflow,
      selectedNode: id,
      selectedSubnode: childId || '',
      selectedBranchId: branchId || 0,
    },
  }),
  [addNode]: blockWhenMonitoring((state, {
    id,
    type = 'after',
    node,
    branchId,
    conditionId,
  }) => {
    let newNodes = state.workflow.nodes;

    if (type === 'before') {
      newNodes = insertNodeBefore(id, node, newNodes);
    } else if (type === 'after') {
      newNodes = insertNodeAfter(id, node, newNodes);
    } else if (type === 'firstNode') {
      newNodes = insertFirstNode(id, node, newNodes, branchId, conditionId);
    }

    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes: newNodes,
      },
    };
  }),
  [updateNode]: blockWhenMonitoring((state, { id, changes }) => {
    const newNodes = updateNodeById(id, changes, state.workflow.nodes);
    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes: newNodes,
      },
    };
  }),
  [removeNode]: blockWhenMonitoring((state, { id }) => {
    let { nodes } = state.workflow;
    const { node, path } = findNodeById(nodes, id);
    if (!node) return state;

    const nextNodeId = node.onSuccess;
    const { node: parent, path: parentPath } = findParentNode(nodes, id);

    // Remove and update link
    nodes = deleteNodeByPath(path, nodes);
    nodes = updateNodeLink(parent, parentPath, id, nextNodeId, nodes);

    return {
      ...state,
      workflow: {
        ...state.workflow,
        nodes,
      },
    };
  }),
}, initialState);

const trackActions = [
  `${addNode}`,
  `${setNodes}`,
  `${removeNode}`,
  `${updateNode}`,
  `${updateWorkflowName}`,
];

export default undoable(reducer, {
  limit: 10,
  filter: action => trackActions.includes(action.type),
});

export const selectWorkflow = state => state.workflowEditor.present?.workflow;

export const selectEventNode = state => selectWorkflow(state).nodes.event;

export const selectSelectedNode = state => {
  const workflow = selectWorkflow(state);
  if (!workflow.selectedNode) return false;

  const { node } = findNodeById(workflow.nodes, workflow.selectedNode);
  return node;
};

export const selectGroupId = state => {
  const { selectedNode, selectedSubnode } = state.workflowEditor.present?.workflow;
  if (selectedSubnode && selectedNode) return selectedNode;

  return undefined;
};

const getNodeId = (_, { nodeId }) => nodeId;

export const selectNodeAndAncestors = createSelector(
  [selectWorkflow, getNodeId],
  (workflow, nodeId) => {
    if (!nodeId) return [];

    const result = [];
    let { node } = findNodeById(workflow.nodes, nodeId);

    while (node) {
      result.push(node);
      node = findParentNode(workflow.nodes, node.id).node;
    }

    const [self, ...ancestors] = result;
    return [self, ...ancestors.reverse()];
  },
);

export const selectSelectedPath = createSelector(
  [state => state, selectSelectedNode],
  (state, selectedNode) => {
    if (selectedNode?.id) return selectNodeAndAncestors(state, { nodeId: selectedNode.id });
    return [];
  },
);

export const selectEditorState = state => state.workflowEditor;

export const selectEditorMode = state => state.workflowEditor.present?.mode;

export const selectEditorView = state => state.workflowEditor.present?.view;

export const selectWorkflowExecutions = state => state.workflowEditor.present?.executions;

export const selectCurrentExecution = state => state.workflowEditor.present?.currentExecution;

export const selectDetails = state => state.workflowEditor.present?.details;

export const selectUndoHistory = createSelector(
  [selectEditorState],
  editorState => {
    const { limit, index } = editorState;
    return { limit, index };
  },
);
