import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logoShort from './logo.png';
import styles from './styles.module.scss';

function SvgLogo() {
  return (
    <svg width="57" height="48" viewBox="0 0 57 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path d="M49.9212 0.000714464H36.4522L32.8599 4.94524L37.8846 16.2626L49.9212 0.000714464ZM51.3932 36.9271H43.75L29.3894 4.01759C29.3894 4.01759 28.7468 2.19468 27.6371 1.04027C26.5755 -0.0640742 24.6223 0.000714464 24.6223 0.000714464H7.62334L12.0054 11.0736H20.7752L23.1305 16.4717L0 48H14.1002L28.6166 29.0376L35.6596 45.1758C35.6596 45.1758 36.1295 46.3656 37.0354 47.1548C38.0063 48 40.0586 48 40.0586 48H56.8L51.3932 36.9271Z" fill="black" />
    </svg>
  );
}

const shortStyle = { mixBlendMode: 'difference' };

function Logo({ narrow, tall, className }) {
  const logo = tall ? <SvgLogo /> : <img src={logoShort} style={shortStyle} alt="X" />;

  return (
    <div className={classNames(styles.root, className)}>
      {logo}
      {!narrow && (<span>Functions</span>)}
    </div>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  narrow: PropTypes.bool,
  tall: PropTypes.bool,
};

Logo.defaultProps = {
  className: undefined,
  narrow: false,
  tall: false,
};

export default Logo;
