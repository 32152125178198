import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setDashboardUrl = createAction('/dashboards/urls/set');

export const fetchDashboardUrl = ({ orgId, dbId }) => async dispatch => {
  const { data } = await axios.get(`/analytics/orgs/${orgId}/dashboards/${dbId}`);
  dispatch(setDashboardUrl({ orgId, dbId, data }));
  return data;
};

export default createReducer({
  [setDashboardUrl]: (state, { orgId, dbId, data }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      [dbId]: data,
    },
  }),
}, {});

export const selectDashboardUrl = (state, orgId, dbId) => (state.dashboards[orgId] || {})[dbId];
