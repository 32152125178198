import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';
import {
  updateFunctionScope as updateOrgFunctionScope,
  updateOrgFunction,
  removeOrgFunction,
  setNewOrgFunction,
} from './orgFunctions';

const setFunctionDetails = createAction('/functions/details/set');
export const unsetFunctionDetails = createAction('/functions/details/unset');
export const updateFunctionSource = createAction('/functions/details/update-source');
export const updateFunctionProps = createAction('/functions/details/update-props');

const selectState = state => state.functionDetails;

export const selectFunctionDetails = (state, { orgId, functionName }) => selectState(state)[`${orgId}/${functionName}`];

export const updateFunctionScope = ({
  orgId,
  functionName,
  scope,
}) => (dispatch, getState) => {
  dispatch(updateOrgFunctionScope({ orgId, functionName, scope }));

  const state = getState();
  const fn = selectFunctionDetails(state, { orgId, functionName });
  if (fn) {
    dispatch(setFunctionDetails({
      orgId,
      functionName,
      data: {
        ...fn,
        scope,
      },
    }));
  }
};

export const updateFunction = ({
  orgId,
  functionName,
  data,
}) => (dispatch, getState) => {
  dispatch(updateOrgFunction({ orgId, functionName, data }));

  const state = getState();
  const fn = selectFunctionDetails(state, { orgId, functionName });
  if (fn) {
    dispatch(setFunctionDetails({
      orgId,
      functionName,
      data: {
        ...fn,
        ...data,
      },
    }));
  }

  return fn;
};

export const publishFunctionVersion = ({ orgId, functionName, version }) => async dispatch => {
  const { data } = await axios.put(`/rpa/orgs/${orgId}/functions/${functionName}/prod`, {
    version,
  });

  dispatch(updateFunctionProps({
    orgId,
    functionName,
    props: data,
  }));

  return data;
};

export const unpublishFunction = ({ orgId, functionName }) => async dispatch => {
  const { data } = await axios.delete(`/rpa/orgs/${orgId}/functions/${functionName}/prod`);

  dispatch(updateFunctionProps({
    orgId,
    functionName,
    props: data,
  }));

  return data;
};
export const fetchFunctionDetails = ({
  orgId,
  functionName,
}) => async dispatch => {
  const { data } = await axios.get(`/rpa/orgs/${orgId}/functions/${functionName}`);

  dispatch(setFunctionDetails({
    orgId,
    functionName,
    data,
  }));
  return data;
};

export const deleteFunction = ({
  orgId,
  functionName,
}) => async dispatch => {
  const { data } = await axios.delete(`/rpa/orgs/${orgId}/functions/${functionName}`);

  dispatch(unsetFunctionDetails({
    orgId,
    functionName,
  }));
  dispatch(removeOrgFunction({
    orgId,
    functionName,
  }));

  return data;
};

export default createReducer({
  [setFunctionDetails]: (state, { orgId, functionName, data }) => ({
    ...state,
    [`${orgId}/${functionName}`]: data,
  }),
  [setNewOrgFunction]: (state, { orgId, data }) => {
    const { functionName } = data;
    const key = `${orgId}/${functionName}`;
    return ({
      ...state,
      [key]: data,
    });
  },
  [updateOrgFunction]: (state, { orgId, functionName, data }) => {
    const key = `${orgId}/${functionName}`;
    const oldData = state[key] || {};
    let { displayName, description, tags } = data;
    if (displayName === 'new function' && oldData.displayName) displayName = oldData.displayName;
    if (description === 'Give your function a description' && oldData.description) description = oldData.description;
    if (tags?.length === 0 && oldData.tags?.length > 0) tags = oldData.tags;

    let newState = {
      ...state,
      [key]: { ...oldData, ...data, displayName },
    };

    if (functionName !== data.functionName) {
      const newKey = `${orgId}/${data.functionName}`;
      const newData = state[newKey] || {};
      displayName = data.displayName;
      description = data.description;
      tags = data.tags;
      if (displayName === 'new function' && newData.displayName) displayName = newData.displayName;
      if (description === 'Give your function a description' && newData.description) description = newData.description;
      if (tags?.length === 0 && newData.tags) tags = newData.tags;

      newState = {
        ...newState,
        [newKey]: {
          ...newData,
          ...data,
          displayName,
          description,
          tags,
        },
        mappings: {
          ...(newState.mappings || {}),
          [key]: newKey,
          [newKey]: key,
        },
      };
    } else if (state.mappings && state.mappings[key]) {
      newState = {
        ...newState,
        [newState.mappings[key]]: newState[key],
      };
    }

    return newState;
  },
  [updateFunctionSource]: (state, { orgId, functionName, draft }) => {
    const key = `${orgId}/${functionName}`;
    return {
      ...state,
      [key]: {
        ...(state[key] || {}),
        draft,
      },
    };
  },
  [updateFunctionProps]: (state, {
    orgId,
    functionName,
    props: { draft, newVersion, ...rest },
  }) => {
    const key = `${orgId}/${functionName}`;
    let versions = (state[key].versions || []);
    if (newVersion && newVersion.version !== 'draft') {
      const [existingDraft, ...others] = versions;
      const idx = others.findIndex(v => v.version === newVersion.version);

      versions = [
        {
          ...existingDraft,
          fromVersion: newVersion.version,
        },
        ...others.slice(0, Math.max(0, idx)),
        newVersion,
        ...others.slice(idx + 1),
      ];
    }

    const newState = {
      ...state,
      [key]: {
        ...(state[key] || {}),
        ...rest,
        versions: [
          {
            ...(versions[0] || {}),
            source: draft || (versions[0] || {}).source,
          },
          ...versions.slice(1),
        ],
      },
    };
    if (newState.mappings && newState.mappings[key]) {
      newState[newState.mappings[key]] = { ...newState[key] };
    }

    return newState;
  },
  [unsetFunctionDetails]: (state, { orgId, functionName }) => {
    const key = `${orgId}/${functionName}`;
    const newState = { ...state };

    delete newState[key];
    if (newState.mappings) {
      delete newState.mappings[key];
      Object.keys(newState.mappings || {}).forEach(k => {
        if (newState.mappings[k] === key) delete newState.mappings[k];
      });
    }

    return newState;
  },
}, {});
