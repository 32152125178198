import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setBillDetails = createAction('/organizations/bills/set');

const billingBaseUrl = '/billing/orgs/';

export const fetchBillDetails = ({ orgId, billMonth }) => async dispatch => {
  let url = billingBaseUrl;
  if (billMonth === 'current') {
    url += `${orgId}/current`;
  } else {
    url += `${orgId}/bills/${billMonth}`;
  }

  const { data } = await axios.get(url);
  dispatch(setBillDetails({ orgId, billMonth, data }));
  return data;
};

export default createReducer({
  [setBillDetails]: (state, { orgId, billMonth, data }) => {
    const orgState = state[orgId] || {};

    return {
      ...state,
      [orgId]: {
        ...orgState,
        [billMonth]: data,
      },
    };
  },
}, {});

export const selectBillDetails = (state, orgId, billMonth) => (state.billDetails[orgId] || {})[billMonth];
