import moment from 'moment';
import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import { selectCurrentOrg } from './user';
import axios from '../utils/api';

const setOrgWebhooks = createAction('/organizations/webhooks/set');
const setOrgWebhook = createAction('/organizations/webhooks/setOne');
const setOrgWebhooksLoading = createAction('/organizations/webhooks/loading');
export const setOrgWebhooksFilter = createAction('/organizations/webhooks/filter');

const isLoading = loadingSince => loadingSince && (moment(loadingSince).diff(moment.now(), 'seconds') < 60);

export const fetchOrganizationWebhooks = ({ orgId }) => async (dispatch, getState) => {
  const state = getState();
  const orgWebhooks = state.orgWebhooks[orgId] || [];

  if (isLoading(orgWebhooks.loadingSince)) return orgWebhooks;

  dispatch(setOrgWebhooksLoading({ orgId, loadingSince: moment.now() }));
  try {
    const { data } = await axios.get(`/rpa/orgs/${orgId}/webhooks`);
    dispatch(setOrgWebhooks({ orgId, data }));
    return data;
  } finally {
    dispatch(setOrgWebhooksLoading({ orgId, loadingSince: false }));
  }
};

export const fetchOrganizationWebhook = ({ orgId, webhookId }) => async dispatch => {
  const { data } = await axios.get(`/rpa/orgs/${orgId}/webhooks/${webhookId}`);
  dispatch(setOrgWebhook({ orgId, webhookId, data }));
};

export const createOrganizationWebhook = ({ orgId, webhook }) => async dispatch => {
  const { data } = await axios.post(`/rpa/orgs/${orgId}/webhooks`, webhook);
  dispatch(setOrgWebhook({ orgId, webhookId: data.webhookId, data }));
};

export default createReducer({
  [setOrgWebhooksLoading]: (state, { orgId, loadingSince }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      loadingSince,
    },
  }),
  [setOrgWebhooks]: (state, { orgId, data }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      webhooks: data,
    },
  }),
  [setOrgWebhook]: (state, { orgId, webhookId, data }) => {
    const orgState = state[orgId] || {};
    const webhooks = orgState.webhooks || [];

    return {
      ...state,
      [orgId]: {
        ...orgState,
        webhooks: [
          ...webhooks.filter(w => w.webhookId !== webhookId),
          data,
        ],
      },
    };
  },
  [setOrgWebhooksFilter]: (state, { orgId, filter }) => ({
    ...state,
    [orgId]: {
      ...(state[orgId] || {}),
      filter,
    },
  }),
}, {});

const selectWebhooksState = state => state.orgWebhooks;

const createStrFilter = filter => str => (str || '').toLowerCase().includes(filter);

export const selectOrganizationWebhooks = (state, orgId) => state.orgWebhooks[orgId];

export const selectOrgWebhooksFilter = (state, orgId) => (selectOrganizationWebhooks(state, orgId) || {}).filter || '';

export const selectOrgWebhook = (state, orgId, webhookId) => {
  const currState = selectOrganizationWebhooks(state, orgId) || {};
  const webhooks = currState.webhooks || [];

  return webhooks.find(w => w.webhookId === webhookId);
};

export const selectFilteredOrgWebhooks = createSelector(
  selectCurrentOrg,
  selectWebhooksState,
  (currentOrg, webhooksState) => {
    const orgWebhookState = webhooksState[currentOrg] || {};
    const filter = createStrFilter(orgWebhookState.filter || '');
    const filterWebhook = webhook => filter(webhook.webhookId) || filter(webhook.name) || filter(webhook.description);

    const webhooks = orgWebhookState.webhooks || [];

    return webhooks.filter(filterWebhook);
  },
);
