import moment from 'moment';
import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import axios from '../utils/api';

/* eslint-disable camelcase */

const setIsLoading = createAction('/users/events/loading');
const setUserEvents = createAction('/users/events/set');
export const addLiveUserEvent = createAction('/users/events/live');
/** Selectors */

const selectState = state => state.userEvents;

const getOrgId = (_, { orgId }) => orgId;

const getUserId = (_, { userId }) => userId;

export const selectUserEvents = createSelector(
  [getOrgId, getUserId, selectState],
  (orgId, userId, state) => {
    const userState = state[`${orgId}/${userId}`] || {};
    if (!userState.data) return {};

    const events = userState.data;

    const bySession = events.sort((e1, e2) => moment(e2.event_timestamp).diff(e1.event_timestamp)).reduce((acc, curr) => {
      const sessionId = (curr.session?.session_id) || curr.eventId;
      const sess = (acc[sessionId] || {});
      let evts = sess.events || [];
      const device = sess.device || curr.device;

      if (
        !curr.event_type?.startsWith('_session')
        && !['add-touchpoint'].includes(curr.event_type)
        && !evts.find(e => e.eventId === curr.eventId)
      ) {
        evts = evts.concat(curr);
      }

      return ({
        ...acc,
        [sessionId]: {
          ...{
            session_id: sessionId,
            isAnalyticalEvent: !!curr.session,
            ...curr.session,
            start_timestamp: Math.min(
              Number(sess.start_timestamp || Number.POSITIVE_INFINITY),
              Number((curr.session && Number(curr.session.start_timestamp)) || Number.POSITIVE_INFINITY),
              moment(curr.time).valueOf(),
            ),
            stop_timestamp: Math.max(
              Number(sess.stop_timestamp || Number.NEGATIVE_INFINITY),
              Number((curr.session && Number(curr.session.stop_timestamp)) || Number.NEGATIVE_INFINITY),
              moment(curr.arrival_timestamp).valueOf(),
            ),
          },
          events: evts,
          device,
        },
      });
    }, {});

    return Object
      .keys(bySession)
      .map(s => bySession[s])
      .filter(s => !!s.events.length)
      .reduce((acc, s) => ({ ...acc, [s.session_id]: s }), { nextToken: userState.nextToken, subscribed: userState.subscribed });
  },
);

export const selectUserEventsLoading = state => {
  const userEvents = selectState(state) || {};
  if (!userEvents.isLoading) return false;

  const loadingFrom = Number(userEvents.isLoading) || 0;
  return Math.abs(loadingFrom - new Date()) < 5000;
};

/** Action dispatchers */

export const fetchUserEvents = ({
  orgId,
  userId,
  refresh,
}) => async (dispatch, getState) => {
  const state = getState();
  if (selectUserEventsLoading(state)) return Promise.resolve();

  dispatch(setIsLoading(+(new Date())));
  try {
    const userState = state.userEvents[`${orgId}/${userId}`] || {};
    const { nextToken: token } = userState;

    const { data: { nextToken, data } } = await axios({
      method: 'GET',
      url: `/audience/orgs/${orgId}/users/${userId}/events`,
      params: { token: refresh ? undefined : token },
    });
    dispatch(setUserEvents({
      orgId,
      userId,
      refresh,
      token,
      nextToken,
      data,
    }));
    return { nextToken, data };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export default createReducer({
  [setIsLoading]: (state, loading) => ({
    ...state,
    isLoading: loading,
  }),
  [setUserEvents]: (state, {
    orgId,
    userId,
    refresh,
    nextToken,
    data,
  }) => {
    const key = `${orgId}/${userId}`;
    const userState = state[key] || {};
    const existingRecords = refresh ? [] : (userState.data || []);

    return {
      ...state,
      [key]: {
        ...userState,
        data: existingRecords.concat(...data),
        nextToken,
      },
    };
  },
  [addLiveUserEvent]: (state, { orgId, userId, event }) => {
    const key = `${orgId}/${userId}`;
    const userState = state[key] || {};
    const existingRecords = userState.data || [];

    return {
      ...state,
      [key]: {
        ...userState,
        data: [{ ...event, live: true }, ...existingRecords],
      },
    };
  },
}, {});

/* eslint-enable camelcase */
