import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Hero from '@x-functions/freyja/lib/components/hero';
import Container from '@x-functions/freyja/lib/components/container';
import Columns from '@x-functions/freyja/lib/components/columns';
import Notification from '@x-functions/freyja/lib/components/notification';
import Heading from '@x-functions/freyja/lib/components/heading';
import Logo from '../../logo';
import styles from './styles.module.scss';

interface AuthLayoutProps {
  onSubmit?: () => void
  renderAs: string | React.Component | React.FunctionComponent
  title?: string,
  subtitle: string
  mainAction: React.ReactNode
  error: string,
  children: React.ReactNode
  hideSeparator: boolean
}

function AuthLayout({
  onSubmit,
  renderAs = 'div',
  title = 'Welcome to X-Functions!',
  subtitle,
  mainAction = null,
  error = '',
  hideSeparator = false,
  children = null,
}: AuthLayoutProps) {
  const ParentElem = renderAs || 'div';

  return (
    <Hero size="fullheight">
      <Hero.Body>
        <Container>
          <Columns centered>
            <Columns.Column
              mobile={{ size: 12 }}
              tablet={{ size: 7 }}
              desktop={{ size: 6 }}
              widescreen={{ size: 5 }}
            >
              {/* @ts-ignore */}
              <ParentElem
                className={styles.form}
                onSubmit={onSubmit}
                renderAs={renderAs}
              >
                <div className={styles.header}>
                  <div className={styles.logo}>
                    <Logo narrow tall />
                  </div>
                  <div>
                    <Heading renderAs="h2" className={styles.title}>{title}</Heading>
                    <Heading renderAs="h6" className={styles.subtitle} subtitle>{subtitle}</Heading>
                  </div>
                </div>
                {mainAction}
                {!hideSeparator && <div className={styles.separator} />}
                {error && <Notification color="danger" className={classNames(styles.error, 'is-light')}>{error}</Notification>}
                {children}
              </ParentElem>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  );
}

AuthLayout.propTypes = {
  onSubmit: PropTypes.func,
  renderAs: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  mainAction: PropTypes.node,
  error: PropTypes.string,
  children: PropTypes.node,
  hideSeparator: PropTypes.bool,
};

AuthLayout.defaultProps = {
  onSubmit: undefined,
  renderAs: 'div',
  mainAction: null,
  error: '',
  children: null,
  title: 'Welcome to X-Functions!',
  hideSeparator: false,
};

export default AuthLayout;
