import { combineReducers } from 'redux';
import user from './user';
import organizations from './organizations';
import orgMembers from './orgMembers';
import orgIntegrations from './orgIntegrations';
import orgFunctions from './orgFunctions';
import orgWebhooks from './orgWebhooks';
import orgWorkflows from './orgWorkflows';
import orgBills from './orgBills';
import functionDetails from './functionDetails';
import functionCatalog from './functionCatalog';
import workflowEditor from './workflowEditor';
import orgAudienceUsers from './orgAudienceUsers';
import userDetails from './userDetails';
import userEvents from './userEvents';
import orgAudienceSegments from './orgAudienceSegments';
import segmentUsers from './segmentUsers';
import metrics from './websiteAnalytics';
import content from './content';
import dashboards from './orgDashboards';
import activityStream from './activityStream';
import htmlEditor from './htmlEditor';
import queryBuilder from './queryBuilder';
import billDetails from './billDetails';
import apiDetails from './apiDetails';
import apiClientApps from './apiClientApps';
import orgSearch from './globalSearch';
import versionInfo from './versionInfo';
import functionUsage from './functionUsage';
import eventData from './eventData';

export default combineReducers({
  user,
  organizations,
  orgMembers,
  orgIntegrations,
  orgFunctions,
  orgWebhooks,
  orgWorkflows,
  orgBills,
  functionDetails,
  functionCatalog,
  workflowEditor,
  orgAudienceUsers,
  orgAudienceSegments,
  segmentUsers,
  userDetails,
  userEvents,
  metrics,
  content,
  dashboards,
  activityStream,
  htmlEditor,
  queryBuilder,
  billDetails,
  apiDetails,
  apiClientApps,
  orgSearch,
  versionInfo,
  functionUsage,
  eventData,
});
