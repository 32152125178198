import { init } from '@x-functions/website-analytics-sdk';
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {
  Authenticator,
  SignIn as AmpSignIn,
  VerifyContact as AmpVerifyContact,
  RequireNewPassword as AmpRequireNewPassword,
  ForgotPassword as AmpForgotPassword,
  Greetings,
} from 'aws-amplify-react';
import { Provider } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ToastProvider } from 'react-toast-notifications';
import { hot } from 'react-hot-loader/root';
import 'codemirror/mode/javascript/javascript';
import useKommunicate from './hooks/useKommunicate';
import createStore from './createStore';
import PageTracker from './components/pageTracker';
import OrgGuard from './components/orgGuard';
import RoleGuard from './components/roleGuard';
import AppShell from './components/shell';
import AuthContext from './components/auth/authContext';
import SignIn from './components/auth/signin';
import VerifyContact from './components/auth/verifyContact';
import RequireNewPassword from './components/auth/requireNewPassword';
import ForgotPassword from './components/auth/forgotPassword';
import GlobalSearch from './components/globalSearch';
import ConfirmProvider from './components/confirm/provider';
import GooglePickerProvider from './components/googlePicker/provider';
import { updateUserRoles } from './reducers/user';
import theme from './muiTheme';
import './App.scss';

const Approval = React.lazy(() => import('./pages/approval'));
const Home = React.lazy(() => import('./pages/home'));
const Analytics = React.lazy(() => import('./pages/analytics'));
const Query = React.lazy(() => import('./pages/query'));
const CLILogin = React.lazy(() => import('./pages/cliLogin'));
const Organization = React.lazy(() => import('./pages/organization'));
const Functions = React.lazy(() => import('./pages/functions'));
const Workflows = React.lazy(() => import('./pages/workflows'));
const Audience = React.lazy(() => import('./pages/audience'));
const Content = React.lazy(() => import('./pages/content'));
const Dashboards = React.lazy(() => import('./pages/dashboards'));
const Vault = React.lazy(() => import('./pages/vault'));

const store = createStore();

init(
  'x-functions',
  process.env.REACT_APP_ANALYTICS_APP_ID || '1608682938136',
  {
    isSPA: true,
    disablePageTracker: true,
    apiDomain: process.env.REACT_APP_ANALYTICS_API_DOMAIN,
  },
);

function ProtectedApp({ authState, onStateChange }: { authState?: string, onStateChange?: () => any } | undefined = {}) {
  React.useEffect(() => {
    if (authState === 'signedIn') {
      (store.dispatch as ThunkDispatch<unknown, unknown, any>)(updateUserRoles());
    }
  }, [authState]);

  if (authState !== 'signedIn') {
    return null;
  }

  return (
    <OrgGuard>
      <AuthContext.Provider
        value={{
          onStateChange: (onStateChange || (() => undefined)),
          authState,
        }}
      >
        <AppShell>
          <RoleGuard>
            <GooglePickerProvider>
              <React.Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route path="/organization" component={Organization} />
                  <Route path="/cli-login" component={CLILogin} />
                  <Route path="/functions" component={Functions} />
                  <Route path="/workflows" component={Workflows} />
                  <Route path="/audience" component={Audience} />
                  <Route path="/content" component={Content} />
                  <Route path="/dashboards" component={Dashboards} />
                  <Route path="/analytics" component={Analytics} />
                  <Route path="/query" component={Query} />
                  <Route path="/vault/auth/:platform" component={Vault} />
                  <Redirect from="/login" to="/" exact />
                  <Route path="/" component={Home} />
                </Switch>
              </React.Suspense>
              <GlobalSearch />
            </GooglePickerProvider>
          </RoleGuard>
        </AppShell>
      </AuthContext.Provider>
    </OrgGuard>
  );
}

ProtectedApp.propTypes = {
  authState: PropTypes.string,
  onStateChange: PropTypes.func.isRequired,
};

ProtectedApp.defaultProps = {
  authState: 'unknown',
};

function ProtectedAppWithAuth() {
  return (
    <Authenticator
      hide={[AmpSignIn, AmpVerifyContact, AmpRequireNewPassword, AmpForgotPassword, Greetings]}
      federated={{
        googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      }}
    >
      <SignIn override="SignIn" />
      <VerifyContact override="VerifyContact" />
      <RequireNewPassword override="RequireNewPassword" />
      <ForgotPassword
        /* @ts-ignore */
        override="ForgotPassword"
      />
      <ProtectedApp />
    </Authenticator>
  );
}

function AppRoutes() {
  useKommunicate();

  return (
    <Switch>
      <Route path="/vault/auth/:platform" component={Vault} />
      <Route path="/approval" component={Approval} />
      <Route path="/" component={ProtectedAppWithAuth} />
    </Switch>
  );
}

function App() {
  return (
    <Router>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <PageTracker />
          <ToastProvider autoDismiss autoDismissTimeout={4000} placement="bottom-center">
            <Provider store={store}>
              <ConfirmProvider>
                <AppRoutes />
              </ConfirmProvider>
            </Provider>
          </ToastProvider>
        </ThemeProvider>
      </StylesProvider>
    </Router>
  );
}

export default hot(App);
