import React from 'react';
import PropTypes from 'prop-types';
import ConfirmContext from './context';
import ConfirmModal from './modal';

const emptyState = {
  open: false,
  title: 'Please Confirm' as React.ReactNode,
  body: 'Do you want to proceed?' as React.ReactNode,
  confirmText: 'Yes',
  cancelText: 'No',
  onClose: (() => undefined) as (result: boolean) => void,
};

function ConfirmProvider({ children = null }: { children?: React.ReactNode }) {
  const [modalState, setModalState] = React.useState(emptyState);

  const confirm = React.useCallback((
    title: React.ReactNode,
    body: React.ReactNode,
    confirmText?: string,
    cancelText?: string,
  ): Promise<boolean> => new Promise(resolve => {
    setModalState({
      open: true,
      title: title || emptyState.title,
      body: body || emptyState.body,
      confirmText: confirmText || emptyState.confirmText,
      cancelText: cancelText || emptyState.cancelText,
      onClose: (result: boolean) => {
        setModalState(emptyState);
        resolve(!!result as boolean);
      },
    });
  }), []);

  const providerState = React.useMemo(() => ({ confirm }), [confirm]);

  return (
    <ConfirmContext.Provider value={providerState}>
      <ConfirmModal {...modalState} />
      {children}
    </ConfirmContext.Provider>
  );
}

ConfirmProvider.propTypes = {
  children: PropTypes.node,
};

ConfirmProvider.defaultProps = {
  children: null,
};

export default ConfirmProvider;
