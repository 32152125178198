import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setOrgAudienceUsers = createAction('/organizations/audience/users/set');

export const fetchOrganizationAudienceUsers = ({ orgId, token }) => async dispatch => {
  const { data: { data, nextToken } } = await axios.get(`/audience/orgs/${orgId}/users`, {
    params: { token, limit: 20 },
  });
  dispatch(setOrgAudienceUsers({
    orgId,
    data,
    nextToken,
    token,
  }));
  return data;
};

export default createReducer({
  [setOrgAudienceUsers]: (state, {
    orgId,
    data,
    nextToken,
    token,
  }) => {
    let finalData = data;
    if (token) {
      const prevData = (state[orgId] || {}).data || [];
      finalData = [...prevData, ...data];
    }

    return {
      ...state,
      [orgId]: {
        ...(state[orgId] || {}),
        data: finalData,
        nextToken,
      },
    };
  },
}, {});

const selectOrgAudienceUsers = state => state.orgAudienceUsers;

export const selectOrgUsers = (state, orgId) => (selectOrgAudienceUsers(state)[orgId] || {}).data;

export const selectNextToken = (state, orgId) => (selectOrgAudienceUsers(state)[orgId] || {}).nextToken;
