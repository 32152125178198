import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import { selectCurrentOrg } from './user';
import axios from '../utils/api';

const setSegmentUsers = createAction('/organizations/audience/segmentUsers/set');

export const fetchSegmentUsers = ({
  orgId,
  segmentId,
  token,
  limit,
}) => async dispatch => {
  const { data } = await axios.get(`/audience/orgs/${orgId}/segments/${segmentId}/users`, { params: { token, limit } });
  dispatch(setSegmentUsers({
    orgId,
    segmentId,
    data,
    token,
  }));
  return data;
};

export default createReducer({
  [setSegmentUsers]: (state, {
    orgId,
    segmentId,
    data,
    token,
  }) => {
    // eslint-disable-next-line no-debugger
    debugger;
    const oldItems = state[`${orgId}/${segmentId}`]?.data || [];
    const newData = data?.data || [];

    return {
      ...state,
      [`${orgId}/${segmentId}`]: {
        nextToken: data?.nextToken,
        data: token ? [...oldItems, ...newData] : newData,
      },
    };
  },
}, {});

const selectState = state => state.segmentUsers;

const selectSegmentId = (_, { segmentId }) => segmentId;

export const selectSegmentUsers = createSelector(
  [selectCurrentOrg, selectState, selectSegmentId],
  (currentOrg, state, segmentId) => state[`${currentOrg}/${segmentId}`],
);
