import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setOrgMembers = createAction('/organizations/members/set');
const setNewOrgMember = createAction('/organizations/members/new');
const updateOrgMember = createAction('/organizations/members/update');
const deleteOrgMember = createAction('/organizations/members/delete');
const setUserDetails = createAction('/organizations/members/set');

export const fetchOrganizationMembers = ({ orgId }) => async dispatch => {
  const { data } = await axios.get(`/orgs/${orgId}/users`);
  dispatch(setOrgMembers({ orgId, data }));
  return data;
};

export const getUserDetails = ({ orgId, userId }) => async dispatch => {
  const { data } = await axios.get(`/orgs/${orgId}/users/${userId}`);
  dispatch(setUserDetails({ orgId, userId, data }));
  return data;
};

export const createOrganizationMember = ({ orgId, member }) => async dispatch => {
  const { data } = await axios.post(`/orgs/${orgId}/users`, member);
  dispatch(setNewOrgMember({ orgId, data }));
  return data;
};

export const changeOrganizationMemberRole = ({ orgId, userId, role }) => async dispatch => {
  const { data } = await axios.put(`/orgs/${orgId}/users/${userId}`, { role });
  dispatch(updateOrgMember({ orgId, userId, data }));
  return data;
};

export const deleteOrganizationMember = ({ orgId, userId }) => async dispatch => {
  await axios.delete(`/orgs/${orgId}/users/${userId}`);
  dispatch(deleteOrgMember({ orgId, userId }));
  return { orgId, userId };
};

export default createReducer({
  [setOrgMembers]: (state, { orgId, data }) => ({
    ...state,
    [orgId]: data,
  }),
  [setNewOrgMember]: (state, { orgId, data }) => ({
    ...state,
    [orgId]: [
      data,
      ...(state[orgId] || []),
    ],
  }),
  [updateOrgMember]: (state, { orgId, userId, data }) => ({
    ...state,
    [orgId]: [
      data,
      ...(state[orgId] || []).filter(m => m.userId !== userId),
    ],
  }),
  [deleteOrgMember]: (state, { orgId, userId }) => ({
    ...state,
    [orgId]: [
      ...(state[orgId] || []).filter(m => m.userId !== userId),
    ],
  }),
  [setUserDetails]: (state, { orgId, userId, data }) => ({
    ...state,
    [`${orgId}/${userId}`]: data,
  }),
}, {});

export const selectOrganizationMembers = (state, orgId) => state.orgMembers[orgId];

export const selectUserDetails = (state, orgId, userId) => state.orgMembers[`${orgId}/${userId}`];
