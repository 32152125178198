import React from 'react';
import theme from '@x-functions/vulcan/lib/theme.sass';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

function useScreenDimensions() {
  const [width, setWidth] = React.useState(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
  const [height, setHeight] = React.useState(Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0));

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
      setHeight(Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0));
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const breakpoints = React.useMemo(() => ({
    upTo: (point: Breakpoint) => width <= (parseInt(theme[`breakpoint-${point}`], 10) || 0),
    atLeast: (point: Breakpoint) => width >= (parseInt(theme[`breakpoint-${point}`], 10) || 0),
    moreThan: (point: Breakpoint) => width > (parseInt(theme[`breakpoint-${point}`], 10) || 0),
    xs: width <= (parseInt(theme['breakpoint-xs'], 10) || 0),
    sm: width <= (parseInt(theme['breakpoint-sm'], 10) || 0),
    md: width <= (parseInt(theme['breakpoint-md'], 10) || 0),
    lg: width <= (parseInt(theme['breakpoint-lg'], 10) || 0),
    xl: width <= (parseInt(theme['breakpoint-xl'], 10) || 0),
  }), [width]);

  return {
    width,
    height,
    breakpoints,
  };
}

export default useScreenDimensions;
