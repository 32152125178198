import React from 'react';
import PropTypes from 'prop-types';
import useCurrentOrg from '../../hooks/useCurrentOrg';

function OrgGuard({ children }) {
  const [orgId] = useCurrentOrg();

  return (
    <div key={orgId}>
      {children}
    </div>
  );
}

OrgGuard.propTypes = {
  children: PropTypes.node,
};

OrgGuard.defaultProps = {
  children: null,
};

export default OrgGuard;
