import React from 'react';
import ReactDOM from 'react-dom';
import SearchModal from './searchModal';

function GlobalSearch() {
  const [searching, setSearching] = React.useState(false);

  const handleClose = React.useCallback(() => {
    setSearching(false);
  }, []);

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === '/') {
        setSearching(v => !v);
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return ReactDOM.createPortal(
    searching ? <SearchModal onClose={handleClose} /> : null,
    document.body,
  );
}

export default GlobalSearch;
