import { createAction, createReducer } from 'redux-act';
import { updateFunctionScope } from './functionDetails';
import axios from '../utils/api';

const setCatalogFunctions = createAction('/catalog/functions/set');
const setNewCatalogFunction = createAction('/catalog/functions/new');

export const fetchCatalogFunctions = ({ orgId, category }) => async dispatch => {
  const { data } = await axios.get(`/catalog/orgs/${orgId}/categories/${category}`);
  dispatch(setCatalogFunctions({ category, data }));
  return data;
};

export const publishFunction = ({
  orgId,
  functionName,
  description,
  category,
  tags,
}) => async dispatch => {
  const { data } = await axios.post(`/catalog/orgs/${orgId}/categories/${category}`, {
    type: 'FUNCTION',
    orgId,
    name: functionName,
    description,
    category,
    tags,
  });
  dispatch(setNewCatalogFunction({ category, data }));
  dispatch(updateFunctionScope({ orgId, functionName, scope: 'public' }));
  return data;
};

export default createReducer({
  [setCatalogFunctions]: (state, { category, data }) => ({
    ...state,
    [category]: data,
  }),
  [setNewCatalogFunction]: (state, { category, data }) => ({
    ...state,
    [category]: (state[category] || []).concat(data),
  }),
}, {});

export const selectCatalogFunctions = (state, category) => state.functionCatalog[category];
export const selectCatalog = state => state.functionCatalog;
