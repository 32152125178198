import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { raiseEvent } from '@x-functions/website-analytics-sdk';
import {
  Field,
  Control,
  Label,
  Input,
  Help,
} from '@x-functions/freyja/lib/components/form';
import Button from '@x-functions/freyja/lib/components/button';
import Layout from '../layout';
import { Auth } from '../../../aws';
import { isValidEmail } from '../../../utils/validations';
import GoogleLogo from '../../googleLogo';

class CustomSignIn extends SignIn {
  private validate: (field: string) => { [fieldName: string]: string | undefined };

  private handleBlur: (e: React.ChangeEvent) => void;

  private handleChange: (e: React.ChangeEvent) => void;

  private handleForgotPasswordClick: (e: React.MouseEvent) => void;

  private handleLoginGoogleClick: (e?: React.MouseEvent) => void;

  private handleSubmit: (e?: React.FormEvent) => void;

  private handleHelpClick: (e: React.MouseEvent) => void;

  private isFormValid: () => boolean;

  constructor(props: any) {
    super(props);

    this.state = {
      username: '',
      /* @ts-ignore */
      password: '',
      errors: {
        username: '',
        password: '',
      },
    };

    this.componentDidUpdate = prevProps => {
      if (prevProps.authState !== this.props.authState && this.props.authState === 'signIn') {
        raiseEvent('pageView', {
          url: `${window.location.origin}/login`,
        });
      }
    };

    this.validate = field => {
      switch (field) {
        case 'username':
          if (!this.state.username) return { [field]: 'Email required' };
          if (!isValidEmail(this.state.username)) return { [field]: 'Invalid email address' };
          return { username: '' };
        case 'password':
          /* @ts-ignore */
          if (!this.state.password) return { [field]: 'Password required' };
          return { password: '' };
        default:
          return {};
      }
    };

    this.handleBlur = (e: React.ChangeEvent) => {
      const { name } = e.target as HTMLInputElement;
      /* @ts-ignore */
      this.setState(state => ({
        errors: {
          /* @ts-ignore */
          ...state.errors,
          /* @ts-ignore */
          ...this.validate(name),
        },
      }));
    };

    this.handleChange = e => {
      const { name, value } = e.target as HTMLInputElement;
      this.setState({ [name]: value }, () => {
        /* @ts-ignore */
        if (this.state.errors[name]) this.handleBlur({ target: { name } });
      });
      this.handleInputChange(e);
    };

    this.handleForgotPasswordClick = e => {
      e.preventDefault();
      e.stopPropagation();

      super.changeState('forgotPassword');
    };

    this.error = e => {
      /* @ts-ignore */
      this.setState(state => ({
        errors: {
          /* @ts-ignore */
          ...state.errors,
          global: e.message,
        },
      }));
      raiseEvent('loginfail', {
        page: 'login',
        event: 'loginfail',
        element: 'handleSubmit',
        error: e.message,
      });
    };

    this.handleLoginGoogleClick = () => {
      /* @ts-ignore */
      Auth.federatedSignIn({ provider: 'Google' });
    };

    this.handleSubmit = e => {
      e?.preventDefault();

      /* @ts-ignore */
      this.setState({
        /* @ts-ignore */
        errors: {
          ...this.validate('username'),
          ...this.validate('password'),
        },
      }, () => {
        if (!this.isFormValid()) return;

        // Ensure Amplify is synchronised with this (it's a bit borked)
        this.handleInputChange({ target: { name: 'username', value: this.state.username } });
        /* @ts-ignore */
        this.handleInputChange({ target: { name: 'password', value: this.state.password } });

        // Now go
        this.setState({ loading: true });
        this.signIn(e).catch(() => {
          this.setState({ loading: false });
        });
      });
    };

    this.handleHelpClick = e => {
      e.preventDefault();

      /* @ts-ignore */
      if (window.Kommunicate) window.Kommunicate.launchConversation();
    };

    /* @ts-ignore */
    this.isFormValid = () => !(this.state.errors.username || this.state.errors.password);
  }

  showComponent() {
    return (
      <Layout
        onSubmit={this.handleSubmit}
        renderAs="form"
        subtitle="Sign in to take your customer experience to the next level."
        mainAction={(
          <Field>
            <Button
              type="button"
              onClick={this.handleLoginGoogleClick}
              fullwidth
              data-xf-name="loginclick"
              data-xf-on="click"
              data-xf-attrs="page:login,event:click,element:googlebutton"
            >
              <GoogleLogo />
              Sign in with Google
            </Button>
          </Field>
        )}
        /* @ts-ignore */
        error={this.state.errors.global}
      >
        <>
          <Field>
            <Label>Email</Label>
            <Control>
              <Input
                type="email"
                placeholder="user@email.com"
                id="username"
                key="username"
                name="username"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.state.username}
                /* @ts-ignore */
                color={this.state.errors.username ? 'danger' : undefined}
              />
            </Control>
            {(this.state as any).errors.username && <Help color="danger">{(this.state as any).errors.username}</Help>}
          </Field>
          <Field>
            <Label pull="left">Password</Label>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="is-pulled-right"
              onClick={this.handleForgotPasswordClick}
              data-xf-name="forgotpasswordclick"
              data-xf-on="click"
              data-xf-attrs="page:login,event:click,element:forgotpasswordlink"
            >
              Forgot your password?
            </a>
            <Control>
              <Input
                type="password"
                placeholder="password"
                id="password"
                key="password"
                name="password"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                /* @ts-ignore */
                value={this.state.password}
                /* @ts-ignore */
                color={this.state.errors.password ? 'danger' : undefined}
              />
            </Control>
            {(this.state as any).errors.password && <Help color="danger">{(this.state as any).errors.password}</Help>}
          </Field>
          <Field>
            <Button
              type="submit"
              color="dark"
              fullwidth
              loading={this.state.loading}
              onClick={this.handleSubmit}
              disabled={!this.isFormValid()}
              data-xf-name="loginclick"
              data-xf-on="click"
              data-xf-attrs="page:login,event:click,element:loginbutton"
            >
              Sign In
            </Button>
          </Field>
          <Field className="has-text-centered">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={this.handleHelpClick}
              data-xf-name="helpclick"
              data-xf-on="click"
              data-xf-attrs="page:login,event:click,element:loginbutton"
            >
              Need help signing in?
            </a>
          </Field>
        </>
      </Layout>
    );
  }
}

export default CustomSignIn;
