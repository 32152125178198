import { VerifyContact } from 'aws-amplify-react';
import PropTypes from 'prop-types';

class CustomVerifyContact extends VerifyContact {
  showComponent() {
    const { authData } = this.props;
    this.changeState('signedIn', authData);

    return null;
  }
}

CustomVerifyContact.propTypes = {
  ...(CustomVerifyContact.propTypes || {}),
  override: PropTypes.string,
};

export default CustomVerifyContact;
