import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { selectUserId, selectUserEmail } from '../reducers/user';

function useKommunicate() {
  const [initialized, setInitialized] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const userId = useSelector(selectUserId, shallowEqual);
  const email = useSelector(selectUserEmail, shallowEqual);

  React.useEffect(() => {
    /* eslint-disable */
    (function(d, m){
      const kommunicateSettings = {
        appId: "36b20bb98addde44b9bddc867daa9a745",
        popupWidget: true,
        automaticChatOpenOnNavigation: true,
        appSettings: {
          chatWidget: {
            popup: false,
          },
        },
        onInit: () => setInitialized(true),
      };
      const s = document.createElement("script"); s.type = "text/javascript"; s.async = true;
      s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
      const h = document.getElementsByTagName("head")[0]; h.appendChild(s);
      window.kommunicate = m; m._globals = kommunicateSettings;
    })(document, window.kommunicate || {});
    /* eslint-enable */
  }, []);

  React.useEffect(() => {
    if (!initialized) return;

    const check = () => {
      if (window.Kommunicate) {
        return setReady(true);
      }
      return setTimeout(check, 500);
    };

    check();
  }, [initialized]);

  React.useEffect(() => {
    if (!ready || !userId || !email) return;

    window.Kommunicate.updateUser({
      email,
      displayName: email,
    });
    window.Kommunicate.updateChatContext({
      userId,
      email,
    });
  }, [ready, userId, email]);
}

export default useKommunicate;
