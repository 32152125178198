import { useSelector, shallowEqual } from 'react-redux';
import { selectUserId, selectUserEmail, selectRoles } from '../reducers/user';

function useCurrentUserData() {
  const userId = useSelector(selectUserId, shallowEqual);
  const email = useSelector(selectUserEmail, shallowEqual);
  const roles = useSelector(selectRoles, shallowEqual);

  return { userId, email, roles };
}

export default useCurrentUserData;
