import { get, set, unset } from 'lodash/fp';
import {
  NodeMap,
  WorkflowNode,
  SubWorkflowNode,
  ConditionalNode,
  ParallelNode,
  WorkflowBranch,
} from './workflowTypes';

type Path = (string | number)[];

type NodeLevelPredicate = (nodes: NodeMap, path: Path) => boolean;

export const forEachNodeLevel = (nodes: NodeMap, predicate: NodeLevelPredicate, path: Path = []) => {
  if (!nodes) {
    return undefined;
  }

  if (predicate(nodes, path)) return 'found';

  const nodeList = Object.values(nodes || {});
  for (let i = 0; i < nodeList.length; i += 1) {
    const node = nodeList[i];
    const childPath = path.concat(node.id);
    if ((node as SubWorkflowNode).nodes) {
      if (forEachNodeLevel((node as SubWorkflowNode).nodes, predicate, childPath.concat('nodes'))) return 'found';
    }
    if ((node as ParallelNode).branches) {
      const parallel = node as ParallelNode;
      for (let j = 0; j < parallel.branches.length; j += 1) {
        const branchPath = childPath.concat('branches', `${j}`, 'nodes');
        if (forEachNodeLevel(parallel.branches[j].nodes, predicate, branchPath)) return 'found';
      }
    }
  }

  return undefined;
};

type FindNodePredicate = (nodes: NodeMap, path?: Path) => WorkflowNode | undefined;
interface FindNodeResults {
  node?: WorkflowNode
  path: Path
}

export const findInNodes = (nodes: NodeMap, predicate: FindNodePredicate, path: Path = []): FindNodeResults => {
  let result = { node: undefined, path } as FindNodeResults;
  forEachNodeLevel(nodes, (nodeLevel, levelPath) => {
    const found = predicate(nodeLevel, levelPath);
    if (found) {
      result = { node: found, path: levelPath };
      return true;
    }
    return false;
  }, path);

  return result;
};

export const findNodeById = (nodes: NodeMap, nodeId: string, path: Path = []): FindNodeResults => {
  const findResults = findInNodes(nodes, nodeLevel => nodeLevel && nodeLevel[nodeId], path);
  return {
    ...findResults,
    path: findResults.node ? findResults.path.concat(nodeId) : findResults.path,
  };
};

export const findParentNode = (nodes: NodeMap, childId: string, path: Path = []): FindNodeResults => {
  const findResults = findInNodes(nodes, nodeLevel => {
    const levelArray = Object.values(nodeLevel || {});
    return levelArray.find(n => {
      if ((n as SubWorkflowNode)?.firstNode === childId || n?.onSuccess === childId) return n;
      if ((n as ConditionalNode)?.onNoMatch === childId || !!(n as ConditionalNode)?.choices?.find(c => c?.onSuccess === childId)) return n;
      if ((n as ParallelNode)?.branches?.find(b => b?.firstNode === childId)) return n;
      return false;
    });
  }, path);
  return {
    ...findResults,
    path: findResults.node ? findResults.path.concat(findResults.node.id) : findResults.path,
  };
};

interface Branch extends Omit<WorkflowBranch, 'id'> {}

interface GetBranchResults {
  branch: Branch
  executionOrder: string[]
}

export const getBranch = (nodes: NodeMap, firstNode: string): GetBranchResults => {
  const branch: Branch = { firstNode, nodes: {} };
  const executionOrder = [];
  let currentNode = nodes[firstNode];
  while (currentNode) {
    branch.nodes[currentNode.id] = currentNode;
    executionOrder.push(currentNode.id);

    currentNode = nodes[currentNode.onSuccess as string];
  }

  return { branch, executionOrder };
};

export const getNodeByPath = get;

export const setNodeByPath = set;

export const deleteNodeByPath = unset;

export const deleteNodeById = (nodes: NodeMap, nodeId: string): NodeMap => {
  const { node, path } = findNodeById(nodes, nodeId);
  if (node) {
    return deleteNodeByPath(path, nodes);
  }

  return nodes;
};

export const setNodeById = (nodes: NodeMap, nodeId: string, newNode: WorkflowNode): NodeMap => {
  const { node, path } = findNodeById(nodes, nodeId);
  if (node) {
    return setNodeByPath(path, newNode, nodes);
  }

  return nodes;
};

export const updateNodeByPath = <T>(path: Path, changes: { [key: string]: any }, tree: T, node?: object): T => {
  const originalNode = node || get(path, tree);
  return set(path, { ...originalNode, ...changes }, tree as unknown as object) as unknown as T;
};

export const updateNodeById = (id: string, changes: { [key: string]: any }, nodes: NodeMap): NodeMap => {
  const { node, path } = findNodeById(nodes, id);
  return updateNodeByPath(path, changes, nodes, node);
};

export const updateNodeLink = (node: WorkflowNode, path: Path, oldChildId: string, newChildId: string, tree: NodeMap) => {
  let newTree = tree;

  // Fix all possible parent links
  if (node?.onSuccess === oldChildId) {
    newTree = updateNodeByPath(path, { onSuccess: newChildId }, newTree);
  }
  if ((node as ConditionalNode)?.onNoMatch === oldChildId) {
    newTree = updateNodeByPath(path, { onNoMatch: newChildId }, newTree);
  }
  if ((node as SubWorkflowNode)?.firstNode === oldChildId) {
    newTree = updateNodeByPath(path, { firstNode: newChildId }, newTree);
  }
  if ((node as ConditionalNode)?.choices) {
    (node as ConditionalNode).choices.forEach((c, idx) => {
      if (c.onSuccess === oldChildId) {
        newTree = updateNodeByPath(
          path.concat('choices', idx),
          { onSuccess: newChildId },
          newTree,
        );
      }
    });
  }
  if ((node as ParallelNode)?.branches) {
    (node as ParallelNode).branches.forEach((b, idx) => {
      if (b.firstNode === oldChildId) {
        newTree = updateNodeByPath(
          path.concat('branches', idx),
          { firstNode: newChildId },
          newTree,
        );
      }
    });
  }

  return newTree;
};

// Add a node to in a direct flow (no forks)
const addNodeDirectFlow = (nodes: NodeMap, parentPath: Path, fieldName: string, newNode: WorkflowNode, nextNodeId: string): NodeMap => {
  let newNodes = nodes;

  // Add new node
  newNodes = setNodeByPath(
    parentPath.slice(0, parentPath.length - 1).concat(newNode.id),
    {
      ...newNode,
      [newNode.type === 'conditional' ? 'onNoMatch' : 'onSuccess']: nextNodeId,
    },
    newNodes,
  );

  // Remap the parent
  newNodes = updateNodeByPath(parentPath, { [fieldName]: newNode.id }, newNodes);

  return newNodes;
};

// Add a node to in a different flow (e.g. conditional)
const addNodeForkedFlow = (nodes: NodeMap, parentPath: Path, forkPath: Path, newNode: WorkflowNode, nextNodeId: string) => {
  let newNodes = nodes;

  // Add new node
  newNodes = setNodeByPath(
    parentPath.slice(0, parentPath.length - 1).concat(newNode.id),
    {
      ...newNode,
      [newNode.type === 'conditional' ? 'onNoMatch' : 'onSuccess']: nextNodeId,
    },
    newNodes,
  );

  // Remap the parent
  newNodes = updateNodeByPath(parentPath.concat(...forkPath), { onSuccess: newNode.id }, newNodes);

  return newNodes;
};

interface Map {
  [key: string]: string
}

export const insertNodeBefore = (id: string, node: WorkflowNode, nodes: NodeMap): NodeMap => {
  let newNodes = nodes;
  const { node: parent, path: parentPath } = findParentNode(newNodes, id);

  // Direct flow?
  const directFlow = ['onSuccess', 'onNoMatch'];
  const directFlowProp = directFlow.find(p => parent && ((parent as unknown as Map)[p] === id));
  if (directFlowProp) {
    newNodes = addNodeDirectFlow(newNodes, parentPath, directFlowProp, node, id);
  } else if (parent?.type === 'conditional') {
    const conditionIndex = parent.choices?.findIndex(c => c.onSuccess === id);
    if (conditionIndex > -1) {
      newNodes = addNodeForkedFlow(
        newNodes,
        parentPath,
        ['choices', conditionIndex],
        node,
        id,
      );
    }
  } else if (parent?.type === 'parallel') {
    const branch = parent.branches.findIndex(b => b.firstNode === id);
    if (branch > -1) {
      // Add new node
      newNodes = setNodeByPath(
        parentPath.concat('branches', branch, 'nodes', node.id),
        {
          ...node,
          [node.type === 'conditional' ? 'onNoMatch' : 'onSuccess']: id,
        },
        newNodes,
      );

      // Remap the parent
      newNodes = updateNodeByPath(parentPath.concat('branches', branch), { firstNode: node.id }, newNodes);
    }
  } else if ((parent as SubWorkflowNode)?.nodes && ((parent as SubWorkflowNode).nodes[id])) {
    // add to parent's nodes
    newNodes = setNodeByPath(
      parentPath.concat('nodes', node.id),
      {
        ...node,
        [node.type === 'conditional' ? 'onNoMatch' : 'onSuccess']: id,
      },
      newNodes,
    );

    // Now fix the link
    newNodes = updateNodeByPath(parentPath, { firstNode: node.id }, newNodes);
  }

  return newNodes;
};

export const insertNodeAfter = (id: string, node: WorkflowNode, nodes: NodeMap): NodeMap => {
  let newNodes = nodes;
  const { node: target, path } = findNodeById(newNodes, id, []);
  // add to parent's nodes
  newNodes = setNodeByPath(
    path.slice(0, path.length - 1).concat(node.id),
    {
      ...node,
      onSuccess: target?.onSuccess,
    },
    newNodes,
  );

  newNodes = updateNodeByPath(path, {
    onSuccess: node.id,
  }, newNodes);

  return newNodes;
};

export const insertFirstNode = (id: string, node: WorkflowNode, nodes: NodeMap, branchId: string, conditionId: string): NodeMap => {
  let newNodes = nodes;
  const { node: target, path } = findNodeById(newNodes, id, []);

  if (target?.type === 'parallel') {
    const idx = target.branches.findIndex(b => b.id === branchId);
    if (idx > -1) {
      // add to parent's nodes
      newNodes = setNodeByPath(
        path.concat('branches', idx, 'nodes', node.id),
        node,
        newNodes,
      );

      newNodes = updateNodeByPath(
        path.concat('branches', idx),
        {
          firstNode: node.id,
        },
        newNodes,
      );
    }
  } else if (target?.type === 'conditional') {
    newNodes = setNodeByPath(
      path.slice(0, path.length - 1).concat(node.id),
      node,
      newNodes,
    );

    if (conditionId === 'onNoMatch') {
      newNodes = updateNodeByPath(path, {
        onNoMatch: node.id,
      }, newNodes);
    } else {
      const idx = target.choices.findIndex(c => c.id === conditionId);
      if (idx > -1) {
        newNodes = updateNodeByPath(
          path.concat('choices', idx),
          {
            onSuccess: node.id,
          },
          newNodes,
        );
      }
    }
  } else {
    newNodes = setNodeByPath(
      path.concat('nodes', node.id),
      node,
      newNodes,
    );
    newNodes = updateNodeByPath(path, {
      firstNode: node.id,
    }, newNodes);
  }

  return newNodes;
};
