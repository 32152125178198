import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setApps = createAction('/organizations/eventData/apps/set');
const setEventTypes = createAction('/organizations/eventData/eventTypes/set');
const setEventSamples = createAction('/organizations/eventData/eventSamples/set');

export const fetchApps = ({ orgId }) => async dispatch => {
  const { data: { data } } = await axios.get(`/rpa/orgs/${orgId}/data/apps`);
  dispatch(setApps({ orgId, data }));
  return data;
};

export const fetchEventTypes = ({ orgId, appId, q }) => async dispatch => {
  const { data: { data } } = await axios.get(`/rpa/orgs/${orgId}/data/eventTypes`, { params: { appId, q } });
  dispatch(setEventTypes({ orgId, appId, data }));
  return data;
};

export const fetchEventSamples = ({ orgId, appId, eventType }) => async dispatch => {
  const { data: { data } } = await axios.get(`/rpa/orgs/${orgId}/data/event`, { params: { appId, eventType } });
  dispatch(setEventSamples({
    orgId,
    appId,
    eventType,
    data,
  }));
  return data;
};

export default createReducer({
  [setApps]: (state, { orgId, data }) => ({
    ...state,
    [orgId]: data,
  }),
  [setEventTypes]: (state, { orgId, appId, data }) => {
    const key = `${orgId}/${appId || 'all'}`;

    return {
      ...state,
      [key]: data,
    };
  },
  [setEventSamples]: (state, {
    orgId,
    appId,
    eventType,
    data,
  }) => {
    const key = `${orgId}/${appId || 'all'}/${eventType}`;

    return {
      ...state,
      [key]: data,
    };
  },
}, {});

export const selectApps = (state, orgId) => state.eventData[orgId];
export const selectEventTypes = (state, { orgId, appId }) => state.eventData[`${orgId}/${appId || 'all'}`];
export const selectEventSamples = (state, { orgId, appId, eventType }) => state.eventData[`${orgId}/${appId || 'all'}/${eventType}`];
