import React from 'react';
import PropTypes from 'prop-types';
import { RequireNewPassword } from 'aws-amplify-react';
import {
  Field,
  Control,
  Label,
  Input,
  Help,
} from '@x-functions/freyja/lib/components/form';
import Button from '@x-functions/freyja/lib/components/button';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Auth } from '@aws-amplify/auth';
import Layout from '../layout';
import { isValidPassword } from '../../../utils/validations';

class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      errors: {
        password: '',
        confirmPassword: '',
      },
    };

    this.validate = field => {
      switch (field) {
        case 'password':
          if (!this.state.password) return { [field]: 'Password required' };
          if (!isValidPassword(this.state.password)) {
            return { [field]: 'Password needs at least 8 characters, a symbol, upper and lower case letters and a number.' };
          }
          return { password: '' };
        case 'confirmPassword':
          if (!this.state.confirmPassword) return { [field]: 'Password confirmation required' };
          if (this.state.password !== this.state.confirmPassword) {
            return { [field]: 'Passwords don\'t match' };
          }
          return { confirmPassword: '' };
        default:
          return {};
      }
    };

    this.handleBlur = e => {
      const { target: { name } } = e;
      this.setState(state => ({
        errors: {
          ...state.errors,
          ...this.validate(name),
        },
      }));
    };

    this.handleChange = e => {
      const { target: { name, value } } = e;
      this.setState({ [name]: value }, () => {
        if (this.state.errors[name]) this.handleBlur({ target: { name } });
      });
      this.handleInputChange(e);
    };

    this.handleBackToSignInClick = e => {
      e.preventDefault();
      e.stopPropagation();

      super.changeState('signIn');
    };

    this.error = e => {
      const global = (e.message || '').replace('Username/client id combination', 'User');
      this.setState(state => ({
        errors: {
          ...state.errors,
          global,
        },
      }));
    };

    this.handleSubmit = e => {
      e.preventDefault();
      e.stopPropagation();

      this.setState({
        errors: {
          ...this.validate('password'),
          ...this.validate('confirmPassword'),
        },
      }, async () => {
        if (!this.isFormValid()) return;

        // Ensure Amplify is synchronised with this (it's a bit borked)
        this.handleInputChange({ target: { name: 'password', value: this.state.password } });

        // Now go
        this.setState({ loading: true });
        try {
          const user = this.props.authData;
          const { password } = this.inputs;
          const { requiredAttributes } = user.challengeParam;
          const attrs = requiredAttributes.reduce((acc, curr) => ({ ...acc, [curr]: this.inputs[curr] }), {});

          await Auth.completeNewPassword(user, password, attrs)
            .then(res => {
              if (res.challengeName === 'SMS_MFA') {
                this.changeState('confirmSignIn', res);
              } else if (res.challengeName === 'MFA_SETUP') {
                this.changeState('TOTPSetup', res);
              } else {
                this.checkContact(res);
              }
            })
            .catch(err => {
              this.setState(state => ({
                errors: {
                  ...state.errors,
                  password: err.message,
                },
              }));

              this.error(err);
            });
        } finally {
          this.setState({ loading: false });
        }
      });
    };

    this.isFormValid = () => !(this.state.errors.password || this.state.errors.confirmPassword);
  }

  showComponent() {
    return (
      <Layout
        subtitle="Your account requires a new password"
        error={this.state.errors.global}
        hideSeparator
      >
        <>
          <Field>
            <Label pull="left">New Password</Label>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              renderAs="a"
              className="is-pulled-right"
              onClick={this.handleBackToSignInClick}
            >
              Go back to sign in
            </a>
            <Control>
              <Input
                type="password"
                placeholder="password"
                id="password"
                key="password"
                name="password"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.state.password}
                color={this.state.errors.password ? 'danger' : undefined}
              />
            </Control>
            {this.state.errors.password && <Help color="danger">{this.state.errors.password}</Help>}
          </Field>
          <Field>
            <Label pull="left">Confirm Password</Label>
            <Control>
              <Input
                type="password"
                placeholder="password"
                id="confirmPassword"
                key="confirmPassword"
                name="confirmPassword"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.state.confirmPassword}
                color={this.state.errors.confirmPassword ? 'danger' : undefined}
              />
            </Control>
            {this.state.errors.confirmPassword && <Help color="danger">{this.state.errors.confirmPassword}</Help>}
          </Field>
          <Field>
            <Button
              type="submit"
              color="dark"
              fullwidth
              loading={this.state.loading}
              disabled={!this.isFormValid()}
              onClick={this.handleSubmit}
            >
              Update Password
            </Button>
          </Field>
        </>
      </Layout>
    );
  }
}

CustomRequireNewPassword.propTypes = {
  ...(CustomRequireNewPassword.propTypes || {}),
  override: PropTypes.string,
};

export default CustomRequireNewPassword;
