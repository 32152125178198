import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setAPIDetails = createAction('/api/details/set');

const selectState = state => state.apiDetails;

export const selectAPIDetails = (state, { orgId }) => selectState(state)[orgId];

export const fetchAPIDetails = ({
  orgId,
}) => async dispatch => {
  const { data: { apiKey, apiDomain } } = await axios.get(`/orgs/${orgId}/api`);

  dispatch(setAPIDetails({
    orgId,
    apiKey,
    apiDomain,
  }));
  return { apiKey, apiDomain };
};

export default createReducer({
  [setAPIDetails]: (state, { orgId, apiKey, apiDomain }) => ({
    ...state,
    [orgId]: { apiKey, apiDomain },
  }),
}, {});
