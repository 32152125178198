import { useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { selectCurrentOrg, setCurrentOrg } from '../reducers/user';

function useCurrentOrg() {
  const orgId = useSelector(selectCurrentOrg, shallowEqual);
  const dispatch = useDispatch();
  const setOrg = useCallback((...args) => dispatch(setCurrentOrg(...args)), [dispatch]);
  return [orgId, setOrg];
}

export default useCurrentOrg;
