import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import { normalize, denormalize } from '../utils/normalize';
import { flip, partiallyApply } from '../utils/combinators';
import { selectCurrentOrg } from './user';
import { updateWorkflowDetails } from './actions';
import axios from '../utils/api';

// turn normalize(obj, idField) into normalize(idField, obj) and then bind idField to 'workflowId';
const normalizeWorkflows = partiallyApply(flip(normalize))('workflowId');

export const setOrgWorkflow = createAction('/organizations/workflows/setOne');
const setOrgWorkflows = createAction('/organizations/workflows/set');
const deleteOrgWorkflow = createAction('/organizations/workflows/delete');

export const fetchOrgWorkflows = ({ orgId, token, functionName }) => async dispatch => {
  const { data } = await axios.get(`/rpa/orgs/${orgId}/workflows`, {
    params: { token, limit: 20, functionName },
  });
  dispatch(setOrgWorkflows({ orgId, data, token }));
  return data;
};

export const deleteWorkflow = ({ orgId, workflowId }) => async dispatch => {
  await axios.delete(`/rpa/orgs/${orgId}/workflows/${workflowId}`);
  dispatch(deleteOrgWorkflow({ orgId, workflowId }));
};

export const createNewWorkflow = ({ orgId }) => dispatch => dispatch(setOrgWorkflow({
  orgId,
  data: {
    workflowId: 'new',
    selectedNode: '',
    name: 'New Workflow',
    firstNode: '',
    nodes: {
      event: {
        id: 'event',
        type: 'event',
        name: 'Event',
        eventType: '',
        onSuccess: '',
      },
    },
  },
}));

export default createReducer({
  [setOrgWorkflow]: (state, { orgId, data }) => {
    const orgState = state[orgId] || {};
    const workflows = orgState.workflows || {};

    return {
      ...state,
      [orgId]: {
        ...orgState,
        workflows: {
          ...workflows,
          [data.workflowId]: data,
          origIds: [...(workflows.origIds || []), data.workflowId],
        },
      },
    };
  },
  [updateWorkflowDetails]: (state, data) => {
    const { orgId } = data;
    const orgState = state[orgId] || {};
    const workflows = orgState.workflows || {};

    return {
      ...state,
      [orgId]: {
        ...orgState,
        workflows: {
          ...workflows,
          [data.workflowId]: data,
          origIds: [data.workflowId, ...(workflows.origIds || []).filter(id => id !== data.workflowId)],
        },
      },
    };
  },
  [setOrgWorkflows]: (state, { orgId, data, token }) => {
    const orgState = state[orgId] || {};
    const { data: items, nextToken } = data || {};
    const [normalized, origIds] = normalizeWorkflows(items || []);
    const newIds = token
      ? [...orgState.origIds, ...origIds]
      : origIds;

    return {
      ...state,
      [orgId]: {
        ...(token ? orgState : {}),
        ...normalized,
        origIds: newIds,
        nextToken,
      },
    };
  },
  [deleteOrgWorkflow]: (state, { orgId, workflowId }) => {
    const workflows = state[orgId] || {};
    const prevIds = workflows.origIds || [];

    return {
      ...state,
      [orgId]: {
        ...workflows,
        [workflowId]: undefined,
        origIds: prevIds.filter(id => id !== workflowId),
      },
    };
  },
}, {});

export const selectWorkflowState = state => state.orgWorkflows;

export const selectCurrentOrgWorkflows = createSelector(
  selectCurrentOrg,
  selectWorkflowState,
  (currentOrg, state) => {
    if (!currentOrg) return [];

    const orgState = state[currentOrg] || {};
    if (!orgState.origIds || !orgState.origIds.length) return [];

    return {
      data: denormalize(orgState, orgState.origIds),
      nextToken: orgState.nextToken,
    };
  },
);
