import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Auth } from '@aws-amplify/auth';
import {
  Field,
  Control,
  Label,
  Input,
  Help,
} from '@x-functions/freyja/lib/components/form';
import Button from '@x-functions/freyja/lib/components/button';
import Layout from '../layout';
import { isValidEmail, isValidPassword } from '../../../utils/validations';

interface ForgotPasswordProps {
  authData: { username: string }
  override: string
}

class CustomForgotPassword extends ForgotPassword {
  private validate: (field: string) => { [fieldName: string]: string | undefined };

  private isFormValid: () => boolean;

  private handleChange: (e?: any) => void;

  private handleBlur: (e?: any) => void;

  private handleResendCodeClick: (e?: any) => void;

  private handleBackToSignInClick: (e?: any) => void;

  private handleSubmit: (e?: any) => void;

  public submit: (e?: any) => void;

  public send: (e?: any) => void;

  constructor(props: ForgotPasswordProps) {
    super(props);

    this.state = {
      /* @ts-ignore */
      loading: false,
      username: '',
      code: '',
      password: '',
      confirmPassword: '',
      errors: {
        username: '',
        code: '',
        password: '',
        confirmPassword: '',
      },
    };

    this.validate = (field: string) => {
      switch (field) {
        case 'username':
          if (!this.state.username) return { [field]: 'Email required' };
          if (!isValidEmail(this.state.username)) return { [field]: 'Invalid email address' };
          return { username: '' };
        case 'password':
          if (!(this.state as any).password) return { [field]: 'Password required' };
          if (!isValidPassword((this.state as any).password)) {
            return { [field]: 'Password needs at least 8 characters, a symbol, upper and lower case letters and a number.' };
          }
          return { password: '' };
        case 'confirmPassword':
          if (!(this.state as any).confirmPassword) return { [field]: 'Password confirmation required' };
          if ((this.state as any).password !== (this.state as any).confirmPassword) {
            return { [field]: 'Passwords don\'t match' };
          }
          return { confirmPassword: '' };
        case 'code':
          if (!(this.state as any).code) return { [field]: 'Verification code required' };
          return { code: '' };
        default:
          return {};
      }
    };

    this.handleBlur = e => {
      const { target: { name } } = e;
      /* @ts-ignore */
      this.setState(state => ({
        errors: {
          /* @ts-ignore */
          ...state.errors,
          ...this.validate(name),
        },
      }));
    };

    this.handleChange = e => {
      const { target: { name, value } } = e;
      /* @ts-ignore */
      this.setState({ [name]: value }, () => {
        if ((this.state as any).errors[name]) this.handleBlur({ target: { name } });
      });
      this.handleInputChange(e);
    };

    this.handleResendCodeClick = e => {
      e.preventDefault();
      e.stopPropagation();

      this.send();
    };

    this.handleBackToSignInClick = e => {
      e.preventDefault();
      e.stopPropagation();

      return super.changeState('signIn');
    };

    this.error = e => {
      const global = (e.message || '').replace('Username/client id combination', 'User');
      /* @ts-ignore */
      this.setState(state => ({
        errors: {
          /* @ts-ignore */
          ...state.errors,
          global,
        },
      }));
    };

    this.submit = async () => {
      const { authData = {} } = this.props;
      const { code, password } = this.inputs;
      const username = this.getUsernameFromInput() || authData.username;

      try {
        await Auth.forgotPasswordSubmit(username, code, password);
        const user = await Auth.signIn(username, password);
        this.changeState('signedIn', user);
        this.setState({ delivery: null });
      } catch (err) {
        /* @ts-ignore */
        this.setState(state => ({
          errors: {
            /* @ts-ignore */
            ...state.errors,
            code: err.message,
          },
        }));
      }
    };

    this.handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const { authData = {} } = this.props;
      const sentCode = (this.state as any).delivery || authData.username;

      this.setState({
        /* @ts-ignore */
        errors: {
          ...this.validate('username'),
          ...(sentCode ? this.validate('code') : {}),
          ...(sentCode ? this.validate('password') : {}),
          ...(sentCode ? this.validate('confirmPassword') : {}),
        },
      }, async () => {
        if (!this.isFormValid()) return;

        // Ensure Amplify is synchronised with this (it's a bit borked)
        this.handleInputChange({ target: { name: 'username', value: (this.state as any).username } });
        if (sentCode) this.handleInputChange({ target: { name: 'code', value: (this.state as any).code } });
        if (sentCode) this.handleInputChange({ target: { name: 'password', value: (this.state as any).password } });

        // Now go

        try {
          /* @ts-ignore */
          this.setState({ loading: true });
          if (sentCode) {
            this.submit(e);
          } else {
            this.send(e);
          }
        } finally {
          /* @ts-ignore */
          this.setState({ loading: false });
        }
      });
    };

    this.isFormValid = () => !(
      (this.state as any).errors.username
      || (this.state as any).errors.code
      || (this.state as any).errors.confirmPassword
      || (this.state as any).errors.password
    );
  }

  showComponent() {
    const { authData = {} } = this.props;

    return (
      <Layout
        onSubmit={this.handleSubmit}
        renderAs={'form' as string}
        title="Reset Password"
        subtitle={
            'Check your email for a verification code to reset your password. '
            + 'If it doesn\'t appear within a few minutes, check your spam folder.'
        }
        error={(this.state as any).errors.global}
        hideSeparator
      >
        <>
          {(this.state as any).delivery || authData.username ? (
            <>
              <Field>
                <Label pull="left">Verification Code</Label>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className="is-pulled-right"
                  onClick={this.handleResendCodeClick}
                >
                  Resend Code
                </a>
                <Control>
                  <Input
                    type="text"
                    placeholder=""
                    id="code"
                    key="code"
                    name="code"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={(this.state as any).code}
                    color={(this.state as any).errors.code ? 'danger' : undefined}
                  />
                </Control>
                {(this.state as any).errors.code && <Help color="danger">{(this.state as any).errors.code}</Help>}
              </Field>
              <Field>
                <Label pull="left">New Password</Label>
                <Control>
                  <Input
                    type="password"
                    placeholder="password"
                    id="password"
                    key="password"
                    name="password"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={(this.state as any).password}
                    color={(this.state as any).errors.password ? 'danger' : undefined}
                  />
                </Control>
                {(this.state as any).errors.password && <Help color="danger">{(this.state as any).errors.password}</Help>}
              </Field>
              <Field>
                <Label pull="left">Confirm Password</Label>
                <Control>
                  <Input
                    type="password"
                    placeholder="confirm password"
                    id="confirmPassword"
                    key="confirmPassword"
                    name="confirmPassword"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={(this.state as any).confirmPassword}
                    color={(this.state as any).errors.confirmPassword ? 'danger' : undefined}
                  />
                </Control>
                {(this.state as any).errors.confirmPassword && <Help color="danger">{(this.state as any).errors.confirmPassword}</Help>}
              </Field>
            </>
          ) : (
            <Field>
              <Label>Email</Label>
              <Control>
                <Input
                  type="email"
                  placeholder="user@email.com"
                  id="username"
                  key="username"
                  name="username"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={(this.state as any).username}
                  color={(this.state as any).errors.username ? 'danger' : undefined}
                />
              </Control>
              {(this.state as any).errors.username && <Help color="danger">{(this.state as any).errors.username}</Help>}
            </Field>
          )}
          <Field>
            <div className="is-flex is-justify-content-space-between">
              <Button onClick={this.handleBackToSignInClick}>
                Go back
              </Button>
              <Button
                type="submit"
                color="dark"
                loading={(this.state as any).loading}
                onClick={this.handleSubmit}
                disabled={!this.isFormValid()}
              >
                {(this.state as any).delivery || authData.username ? 'Reset Password' : 'Send code'}
              </Button>
            </div>
          </Field>
        </>
      </Layout>
    );
  }
}

export default CustomForgotPassword;
