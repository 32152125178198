import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setOrgBills = createAction('/organizations/bills/set');

export const fetchOrganizationBills = ({ orgId, token }) => async dispatch => {
  const { data } = await axios.get(`/billing/orgs/${orgId}`, { params: { token } });
  dispatch(setOrgBills({ orgId, data, token }));
  return data;
};

export default createReducer({
  [setOrgBills]: (state, { orgId, data, token }) => {
    const orgState = state[orgId] || {};
    const prevData = orgState.data || [];
    const newData = token ? [...prevData, ...data.data] : data.data;

    return {
      ...state,
      [orgId]: {
        ...orgState,
        data: newData,
        nextToken: data.nextToken,
      },
    };
  },
}, {});

export const selectOrganizationBills = (state, orgId) => state.orgBills[orgId] || {};
