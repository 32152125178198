import { createAction, createReducer } from 'redux-act';
import axios from '../utils/api';

const setSearchResults = createAction('/organizations/search/set');
export const clearResults = createAction('/organizations/search/clear');

export const selectSearchResults = (state, orgId) => state.orgSearch[orgId] || {};

export const search = ({ orgId, query }) => async dispatch => {
  if (!query) return undefined;

  const { data } = await axios.get(`/rpa/orgs/${orgId}/search`, { params: { query } });

  dispatch(setSearchResults({
    orgId,
    data,
    query: query || '',
  }));
  return data;
};

export default createReducer({
  [setSearchResults]: (state, {
    orgId,
    data: { data },
  }) => ({
    ...state,
    [orgId]: data,
  }),
  [clearResults]: (state, {
    orgId,
  }) => ({
    ...state,
    [orgId]: {},
  }),
}, {});
