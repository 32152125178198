import React from 'react';
import { useLocation } from 'react-router-dom';
import { raiseEvent } from '@x-functions/website-analytics-sdk';

function PageTracker() {
  const location = useLocation();
  React.useEffect(() => {
    raiseEvent('pageView', {
      url: `${window.location.origin}${location.pathname}`,
      search: location.search,
    });
  }, [location]);
  return null;
}

export default PageTracker;
